import styled, { css } from 'styled-components';
export const TabsHeight = 35;
export const TabsComponent = styled.div `
  display: flex;
  height: ${TabsHeight}px;
  position: relative;
  overflow: auto;
  border-bottom: 1px solid rgba(17, 21, 24, 0.25);
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`;
export const TabComponent = styled.div `
  position: relative;
  display: flex;
  white-space: nowrap;
  height: ${TabsHeight}px;
  box-sizing: border-box;
  padding-left: 10px;
  align-items: center;
  width: 120px;
  min-width: fit-content;
  border-right: 1px solid rgba(17, 21, 24, 0.5);
  &:hover {
    ${props => !props.active &&
    css `
        background-color: rgba(19, 124, 189, 0.125) !important;
      `}
  }
  ${props => props.active &&
    css `
      background-color: rgba(19, 124, 189, 0.25) !important;
    `}
`;
export const TabLabel = styled.div `
  display: flex;
  color: ${props => (props.active ? 'rgb(255, 255, 255)' : 'rgba(255, 255, 255, 0.75)')};
  white-space: nowrap;
  flex: 1;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  white-space: pre;
  text-decoration: none;
  font-size: 13px;
  cursor: ${props => (props.active ? 'default' : 'pointer')};
`;
export const TabClose = styled.div `
  margin-top: auto;
  margin-bottom: auto;
  width: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.75);
  &:hover {
    color: rgb(255, 255, 255);
  }
`;
