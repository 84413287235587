export const sandboxTemplate = 'sandbox';
// TODO Add dynamic download of templates
export const templates = {
    [sandboxTemplate]: {
        name: 'sandbox',
        entry: 'template.pf',
        preview: 'readme.rid',
        previewHidden: true,
        description: 'Sandbox template',
        content: require('./sandbox.json')
    },
    basicsTypes: {
        name: 'tutorial/basics/Types',
        entry: 'structure.pf',
        description: 'Basic Types Tutorial',
        preview: 'readme.rid',
        previewHidden: true,
        content: require('./basics.types.json')
    },
    basicsRPC: {
        name: 'tutorial/basics/RPC',
        entry: 'rpc.pf',
        description: 'Basic RPC Tutorial',
        preview: 'readme.rid',
        previewHidden: true,
        content: require('./basics.rpc.json')
    },
    advancedTypes: {
        name: 'tutorial/advanced/Types',
        entry: 'types.pf',
        description: 'Advanced Types Tutorial',
        preview: 'readme.rid',
        previewHidden: true,
        content: require('./advanced.types.json')
    },
    advancedServices: {
        name: 'tutorial/advanced/Services',
        entry: 'services.pf',
        description: 'Advanced Services Tutorial',
        preview: 'readme.rid',
        previewHidden: true,
        content: require('./advanced.services.json')
    },
    advancedREST: {
        name: 'tutorial/advanced/REST',
        entry: 'rest.pf',
        description: 'REST overlay Tutorial',
        preview: 'readme.rid',
        previewHidden: true,
        content: require('./advanced.rest.json')
    },
    examplesAuth: {
        name: 'examples/Auth',
        entry: 'auth.pf',
        description: 'Basic Auth Example',
        preview: 'readme.rid',
        previewHidden: true,
        content: require('./examples.auth.json')
    },
    examplesAdvAuth: {
        name: 'examples/ComplexAuth',
        entry: 'auth.pf',
        description: 'Advanced Auth Example',
        preview: 'readme.rid',
        previewHidden: true,
        content: require('./examples.advauth.json')
    },
    examplesStripe: {
        name: 'examples/Stripe',
        entry: 'stripe.pf',
        description: 'Stripe Product API',
        preview: 'readme.rid',
        previewHidden: true,
        content: require('./examples.stripe.json')
    },
    examplesPetstore: {
        name: 'examples/PetStore',
        entry: 'petstore.pf',
        description: 'PetStore Example',
        preview: 'readme.rid',
        previewHidden: true,
        content: require('./examples.petstore.json')
    }
};
