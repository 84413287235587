import styled from 'styled-components';
// import { CodeReportHeight } from '../CodeReport/styles';
// import { TabsHeight } from '../CodeTabs/styles';
export const MonacoEditorWrapper = styled.div `
  display: flex;
  flex-grow: 1;
  outline: none;
  overflow: hidden;
  left: 0px;
  right: 0px;
`;
// height: calc(100% - ${props => TabsHeight + (props.readonly ? 0 : CodeReportHeight)}px);
// position: absolute;
