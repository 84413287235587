import styled from 'styled-components';
export const IconContainer = styled.div `
  display: flex;
  vertical-align: middle;
  line-height: 1;
  align-items: center;
`;
export const Icon = styled.div `
  position: relative;
  display: inline-block;
  transition: 0.3s ease color;
  cursor: pointer;

  padding-left: 0.5rem;
  color: rgba(255, 255, 255, 0.5);
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;
