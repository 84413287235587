import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { sha256 } from 'js-sha256';
import SplitPane from 'react-split-pane';
import { Callout, Button, NonIdealState } from '@blueprintjs/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { File as PFFile, ProvidedContent, RemoteContent, FileContent as PFFileContent } from '@protoforce/projects';
import { HashAlgorithm, Hash, Lang } from '@protoforce/shared';
import { saveZip } from '~/core/zipper';
import { showSuccessToast, showErrorToast } from '~/core/toasters';
import { AnalyticsContext } from '~/core/analytics';
import { MonacoEditor } from './CodeEditor';
import { CodeFileType, fileTypeFromName, isProtoforceFile, debounce, getFileExt } from './common';
import { defaultStorage } from './storage';
import { CodeTree, newFileID } from './CodeTree';
import { CodeTabs } from './CodeTabs';
import { parserService } from './core/engine/parser.service';
import { CodeReport } from './CodeReport';
import { CodeTemplates, templates, TemplatePreview } from './CodeTemplates';
import { StorageFull, PreviewHeader, LeftPaneContent, PreviewOverlay, NonIdealWrapper } from './styles';
import { LangPicker } from './LangPicker';
import { idlToEngineLang } from './core/langs';
export * from './CodeTemplates/templates';
export const codeSandboxDefaultOptions = {
    leftPaneMin: 200,
    leftPaneMax: 500,
    leftPaneDef: 250,
    readonly: false,
    singleFile: false,
    allowCodePreview: true,
    allowTemplates: true,
    allowReport: true,
    parseFrequency: 1000,
    saveFrequency: 2000,
    allowedExtensions: undefined,
    extensionsEditor: undefined,
    storage: defaultStorage
};
const codeSandboxPreviewOptions = {
    leftPaneMin: 200,
    leftPaneMax: 500,
    leftPaneDef: 250,
    readonly: true,
    singleFile: false,
    allowCodePreview: false,
    allowTemplates: false,
    allowReport: false,
    parseFrequency: 1000,
    saveFrequency: 2000,
    allowedExtensions: undefined,
    extensionsEditor: undefined,
    storage: defaultStorage
};
function calcHash(content) {
    return sha256(content);
}
function prepareFiles(files, cleanHash) {
    const convertFile = (f) => {
        const { content, hash } = f.content.match(whenProvided => {
            return {
                content: {
                    type: 'local',
                    data: whenProvided.data
                },
                hash: f.hash.alg !== HashAlgorithm.SHA256 ? calcHash(whenProvided.data) : f.hash.value
            };
        }, whenRemote => {
            return {
                content: {
                    type: 'remote',
                    downloading: false,
                    url: whenRemote.url
                },
                hash: undefined
            };
        });
        return {
            id: newFileID(),
            name: f.name,
            hash: hash || '',
            originalHash: cleanHash ? undefined : hash,
            content,
            type: fileTypeFromName(f.name)
        };
    };
    let resFiles = [];
    if (files) {
        if (Array.isArray(files)) {
            resFiles = files.map(f => convertFile(f));
        }
        else {
            if (files instanceof PFFile) {
                resFiles = [convertFile(files)];
            }
            else {
                resFiles = Object.keys(files).map(k => {
                    const oh = calcHash(files[k]);
                    return {
                        id: newFileID(),
                        name: k,
                        hash: oh,
                        originalHash: oh,
                        content: {
                            type: 'local',
                            data: files[k]
                        },
                        type: fileTypeFromName(k)
                    };
                });
            }
        }
    }
    const res = {};
    resFiles.forEach(f => (res[f.id] = f));
    return res;
}
const SINGLE_VIEW_WARN = 'Single file code sandbox provided with multiple fiels.';
export function CodeSandbox(props) {
    const { title, template, saveKey, header, onChanged } = props;
    const options = useMemo(() => {
        return Object.assign(Object.assign({}, codeSandboxDefaultOptions), (props.options || {}));
    }, [props.options]);
    const [files, setFiles] = useState(() => {
        const prepared = loadState() || prepareFiles(props.files);
        if (options.singleFile && Object.keys(prepared).length > 1) {
            console.warn(SINGLE_VIEW_WARN);
        }
        return prepared;
    });
    const [openFiles, setOpenFiles] = useState(() => {
        const keys = Object.keys(files);
        return keys.length > 0 ? [files[keys[0]]] : [];
    });
    const [activeFile, setActiveFile] = useState(() => {
        const f = openFiles[0];
        return f ? f.id : undefined;
    });
    const analytics = useContext(AnalyticsContext);
    const codeEditorRef = useRef(null);
    const codeTreeRef = useRef(null);
    const codeTemplateRef = useRef(null);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [previewCompiling, setPreviewCompiling] = useState(false);
    const [previewFiles, setPreviewFiles] = useState({});
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewLang, setPreviewLang] = useState(Lang.TypeScript);
    const [storageFull, setStorageFull] = useState(false);
    const [parseResult, setParseResult] = useState();
    const [parseFilesErrors, setParseFilesErrors] = useState({});
    const codeOpenFiles = useMemo(() => {
        // TODO We don't support downloadable content here, should add
        // in case we need for text files as well.
        return openFiles.filter(f => f.type === CodeFileType.Code && f.content.type === 'local');
    }, [openFiles]);
    function initFromState(prepared, parseAfter) {
        if (options.singleFile && Object.keys(prepared).length > 1) {
            console.warn(SINGLE_VIEW_WARN);
        }
        setFiles(prepared);
        // TODO Make it slightly better, re-select files that were open and remove the ones
        // that are no longer valid?
        const keys = Object.keys(prepared);
        const opf = keys.length > 0 ? prepared[keys[0]] : undefined;
        setOpenFiles(opf ? [opf] : []);
        setActiveFile(opf ? opf.id : undefined);
        if (parseAfter) {
            parseCode();
        }
    }
    useEffect(() => {
        if (previewOpen) {
            compileCode();
        }
    }, [previewLang, previewOpen]);
    useEffect(() => {
        if (props.files && template) {
            console.warn('Trying to set both template and files, ignoring template.');
        }
        // If we have previous saved state, let's use that
        const previous = loadState();
        if (previous) {
            initFromState(previous, !options.readonly);
            return;
        }
        if (!props.files && template) {
            const t = templates[template];
            if (t) {
                onLoadTemplate(t, true);
            }
        }
        else {
            const prepared = prepareFiles(props.files);
            initFromState(prepared, !options.readonly);
        }
    }, [template, props.files]);
    function loadState() {
        if (!saveKey) {
            return undefined;
        }
        const data = options.storage.load(saveKey);
        if (!data) {
            return;
        }
        return JSON.parse(data);
    }
    function saveStateImpl() {
        if (!saveKey) {
            return;
        }
        const data = JSON.stringify(files);
        const success = options.storage.save(saveKey, data);
        // TODO Make this more intelligent, don't udpate each time?
        setStorageFull(!success);
        // console.log('Save state');
    }
    const saveState = debounce(saveStateImpl, options.saveFrequency);
    function buildFilesFS() {
        const fs = {};
        Object.values(files)
            .filter(f => isProtoforceFile(f.name) && f.content.type === 'local')
            .forEach(f => (fs[f.name] = f.content.data));
        return fs;
    }
    function compileCode() {
        const fs = buildFilesFS();
        const engineLang = idlToEngineLang(previewLang);
        if (typeof engineLang === 'undefined') {
            // Should not happen if all langs are supported
            return;
        }
        setPreviewCompiling(true);
        parserService.compile(fs, engineLang, (result) => {
            // TODO Fix this here, if we close before compilation finishes - we'll have
            // a leak.
            setPreviewCompiling(false);
            if (result.errors.length > 0) {
                setPreviewFiles({});
                showErrorToast(result.errors.map(e => e.message).join('\n'));
            }
            else {
                setPreviewFiles(result.fs);
            }
        });
    }
    function parseCodeImpl() {
        const fs = buildFilesFS();
        parserService.parse(fs, (result) => {
            setParseResult(result);
            // Extract all errors and see if we can map to our files
            const filesErrors = {};
            const objFiles = Object.values(files);
            result.errors.forEach(e => {
                if (!e.location) {
                    return;
                }
                const loc = e.location.path.toLowerCase();
                const f = objFiles.find(fi => fi.name.toLowerCase() === loc);
                if (!f) {
                    return;
                }
                if (f.id in filesErrors) {
                    filesErrors[f.id].push(e);
                }
                else {
                    filesErrors[f.id] = [e];
                }
            });
            // TODO Make this more intelligent, don't udpate each time?
            setParseFilesErrors(filesErrors);
            // console.log(JSON.stringify(result, undefined, 2));
        });
        // parserService.compile(fs, EngineSupportedLanguage.Typescript, (result: CompileResult) => {
        //     console.log(result);
        // });
    }
    const parseCode = debounce(parseCodeImpl, options.parseFrequency);
    function doOnChangedNotif(fromFiles) {
        if (!onChanged) {
            return;
        }
        const fileToPF = (v) => {
            const pf = new PFFile();
            pf.content = PFFileContent.from(v.content.type === 'local'
                ? new ProvidedContent({
                    data: v.content.data
                })
                : new RemoteContent({
                    url: v.content.url
                }));
            pf.hash = new Hash();
            pf.hash.alg = HashAlgorithm.SHA256;
            pf.hash.value = v.hash;
            pf.name = v.name;
            return pf;
        };
        const getFiles = () => {
            const values = Object.values(fromFiles);
            const res = values.map(v => fileToPF(v));
            return res;
        };
        const getUpdatedFiles = () => {
            const values = Object.values(fromFiles);
            const changed = values.filter(v => v.originalHash !== v.hash).map(v => fileToPF(v));
            // TODO!
            // const deleted: string[] = [];
            return { changed, deleted: [] };
        };
        onChanged(getFiles, getUpdatedFiles);
    }
    function onStateChanged(updated, deleted) {
        // We need to update hashes of all changed files
        updated.forEach(f => {
            const data = files[f];
            switch (data.content.type) {
                case 'local':
                    data.hash = calcHash(data.content.data);
                    break;
                default:
                // Do nothing for now
            }
        });
        if (onChanged) {
            doOnChangedNotif(files);
        }
        parseCode();
        saveState();
    }
    function onCodeChange(fileID, content) {
        if (options.readonly) {
            return;
        }
        if (codeOpenFiles.findIndex(f => f.id === fileID) < 0) {
            console.warn(`onCodeChange called on file ${fileID} which is not in codeOpenFiles list.`);
            return;
        }
        const fileData = files[fileID];
        if (!fileData) {
            console.warn(`onCodeChange called on file ${fileID} which is not in the list of files.`);
            return;
        }
        if (fileData.content.type !== 'local') {
            console.warn(`onCodeChange called on file ${fileID} which is not local.`);
            return;
        }
        fileData.content.data = content;
        onStateChanged([fileID], []);
    }
    function onExtFileUpdate(f) {
        const fileData = files[f.id];
        if (!fileData) {
            console.warn(`onExtFileUpdate called on file ${f.id} which is not in the list of files.`);
            return;
        }
        if (fileData.content.type !== 'local') {
            console.warn(`onExtFileUpdate called on file ${f.id} which is not local.`);
            return;
        }
        onStateChanged([f.id], []);
    }
    function onDownload() {
        const now = new Date();
        const zipName = (title || 'sandbox') +
            ` ${now.getFullYear()}-${('0' + (now.getMonth() + 1)).slice(-2)}-${('0' + now.getDate()).slice(-2)}T${('0' + now.getHours()).slice(-2)}-${('0' + now.getMinutes()).slice(-2)}-${('0' + now.getSeconds()).slice(-2)}` +
            '.zip';
        const content = Object.values(files)
            .filter(f => f.content.type === 'local')
            .map(f => ({
            name: f.name,
            content: f.content.data
        }));
        saveZip(zipName, content);
    }
    function onFilesCreated(fs) {
        fs.forEach(f => {
            const content = f.content || '';
            const hash = calcHash(content);
            files[f.id] = {
                name: f.name,
                id: f.id,
                hash,
                content: {
                    type: 'local',
                    data: content
                },
                type: fileTypeFromName(f.name)
            };
        });
        onStateChanged(fs.map(f => f.id), []);
        // console.log('Created: ', fs);
    }
    function onFilesDeleted(ids) {
        ids.forEach(id => {
            delete files[id];
        });
        const updatedOpen = openFiles.filter(f => ids.indexOf(f.id) < 0);
        if (updatedOpen.length !== openFiles.length) {
            setOpenFiles(updatedOpen);
            if (ids.indexOf(activeFile || '') >= 0) {
                setActiveFile(updatedOpen.length > 0 ? updatedOpen[0].id : undefined);
            }
        }
        onStateChanged([], ids);
        // console.log('Deleted: ', ids);
    }
    function onFilesRenamed(fs) {
        const ids = fs.map(f => f.id);
        fs.forEach(f => {
            files[f.id].name = f.name;
        });
        const renamedOpen = openFiles.find(of => ids.indexOf(of.id) >= 0) ? true : false;
        if (renamedOpen) {
            setOpenFiles(openFiles);
        }
        onStateChanged(ids, []);
        // console.log('Renamed: ', fs);
    }
    function onFileClicked(fileID) {
        if (codeTemplateRef.current) {
            codeTemplateRef.current.select();
            setSelectedTemplate(undefined);
        }
        const f = files[fileID];
        if (!f) {
            console.warn(`Inconsistent state of sandbox, file: ${fileID} is not found in: ${Object.keys(files).join(', ')}`);
            return;
        }
        if (openFiles.findIndex(o => o.id === f.id) < 0) {
            setOpenFiles([...openFiles, f]);
        }
        setActiveFile(f.id);
        // console.log('Selected: ', newFile, selected);
    }
    function onTabChange(id) {
        setActiveFile(id);
        if (codeTreeRef.current) {
            codeTreeRef.current.select(id);
        }
    }
    function onTabClose(id) {
        const updated = openFiles.filter(f => f.id !== id);
        if (activeFile === id) {
            if (updated.length === 0) {
                setActiveFile(undefined);
            }
            else {
                const tabPos = openFiles.findIndex(f => f.id === id);
                const newPos = tabPos >= updated.length ? updated.length - 1 : tabPos;
                setActiveFile(updated[newPos].id);
            }
        }
        setOpenFiles(updated);
    }
    function onSelectTemplate(t) {
        setSelectedTemplate(t);
        if (codeTreeRef.current) {
            codeTreeRef.current.select();
        }
    }
    function onLoadSelectedTemplate() {
        if (!selectedTemplate) {
            return;
        }
        const t = selectedTemplate;
        // Deselect template
        setSelectedTemplate(undefined);
        if (codeTemplateRef.current) {
            codeTemplateRef.current.select();
        }
        // Load it now
        onLoadTemplate(t, true);
    }
    function onLoadTemplate(t, ignoreSavedState) {
        // TODO Remove this and make via context
        analytics.sandbox('LoadTemplate', t.name);
        if (!ignoreSavedState) {
            const savedState = loadState();
            if (savedState) {
                initFromState(savedState, !options.readonly);
                return;
            }
        }
        let templateContent = t.content;
        if (t.previewHidden && t.preview) {
            templateContent = {};
            Object.keys(t.content).forEach(k => {
                if (k !== t.preview) {
                    templateContent[k] = t.content[k];
                }
            });
        }
        const prepared = prepareFiles(templateContent, true);
        setFiles(prepared);
        const entryFile = t.entry ? Object.values(prepared).find(p => p.name === t.entry) : undefined;
        setOpenFiles(entryFile ? [entryFile] : []);
        setActiveFile(entryFile ? entryFile.id : undefined);
        if (codeTreeRef.current && entryFile) {
            const ctr = codeTreeRef.current;
            setTimeout(() => {
                ctr.select(entryFile.id);
            }, 250);
        }
        if (!options.readonly) {
            // Have a delay, as we will need files to be updated
            parseCode();
            doOnChangedNotif(prepared);
            saveState();
        }
        showSuccessToast(`Loaded ${t.name} template.`);
    }
    function onReportEntityClick(err) {
        if (!codeEditorRef.current) {
            return;
        }
        const ce = codeEditorRef.current;
        if (!err.location) {
            return;
        }
        const errPath = err.location.path.toLowerCase();
        const errPos = err.location.pos ? err.location.pos.start : undefined;
        const errorFile = Object.values(files).find(f => f.name.toLowerCase() === errPath);
        if (!errorFile) {
            return;
        }
        if (errorFile.type !== CodeFileType.Code) {
            console.warn('Trying to open a non code file from the error or warning.');
            return;
        }
        const gotoFile = (immediate) => {
            if (immediate) {
                ce.gotoFile(errorFile.id, errPos);
            }
            else {
                setTimeout(() => {
                    ce.gotoFile(errorFile.id, errPos);
                }, 100);
            }
        };
        let delayGoto = false;
        // Check if file was opened before, if not - load the model
        // into the editor and update list of open files
        if (!openFiles.find(of => of.id === errorFile.id)) {
            setOpenFiles([...openFiles, errorFile]);
            ce.loadModel(errorFile);
            delayGoto = true;
        }
        // Check if file was not active, then activate it
        if (errorFile.id !== activeFile) {
            setActiveFile(errorFile.id);
            delayGoto = true;
        }
        gotoFile(delayGoto);
        // console.log(err);
    }
    function onCopyContent() {
        const content = {};
        Object.values(files)
            .map(f => {
            // if (isCodeFile(f.name)) {
            return { name: f.name, content: f.content.type === 'local' ? f.content.data : `{remote: ${f.content.url}}` };
            // } else {
            // return {name: f.name, content: `Not supported`};
            // }
        })
            .forEach(f => (content[f.name] = f.content));
        const json = JSON.stringify(content, undefined, 2);
        // TODO A bit hacky here ... maybe come up with a different way of doing this?
        const copyToClipboard = (str) => {
            const el = document.createElement('textarea');
            el.value = str;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        };
        copyToClipboard(json);
        showSuccessToast('Content copied.');
    }
    function renderFile(af, editable) {
        const ro = options.readonly || !editable;
        switch (af.type) {
            case CodeFileType.Code:
                // TODO Keep editor mounted, so we can quickly switch between tabs without
                // losing the model state in it
                return (React.createElement(React.Fragment, null,
                    React.createElement(MonacoEditor, { files: codeOpenFiles, activeFile: activeFile, readonly: ro, onChange: onCodeChange, ref: codeEditorRef, errors: parseFilesErrors, symbols: parseResult ? parseResult.models : undefined }),
                    !ro && options.allowReport && (React.createElement(CodeReport, { errors: parseResult ? parseResult.errors : [], warnings: parseResult ? parseResult.warnings : [], symbols: parseResult ? parseResult.models : [], onErrorClick: onReportEntityClick, onWarningClick: onReportEntityClick }))));
            case CodeFileType.Unknown: {
                if (options.extensionsEditor) {
                    // TODO useMemo here?
                    // TODO Move this to the beginning - so we can override behavior from outside?
                    const ext = getFileExt(af.name);
                    const ExtEditor = options.extensionsEditor[ext];
                    if (ExtEditor) {
                        return React.createElement(ExtEditor, { file: af, readonly: ro, onChange: onExtFileUpdate });
                    }
                }
            }
            default:
                return React.createElement("div", null,
                    "Unsupported file format. ",
                    af.name);
        }
    }
    function renderActiveFile() {
        if (!activeFile) {
            return (React.createElement(NonIdealWrapper, null,
                React.createElement(NonIdealState, { icon: 'folder-open', title: 'No file selected' }, "You have no files open. Pick something to continue, or load a template to get started.")));
        }
        const af = files[activeFile];
        return renderFile(af, true);
    }
    function onCodePreview() {
        if (!previewOpen) {
            analytics.sandbox('PreviewCode');
        }
        setPreviewOpen(!previewOpen);
    }
    if (options.singleFile) {
        return renderActiveFile();
    }
    else {
        return (React.createElement("div", null,
            React.createElement(SplitPane, { split: 'vertical', minSize: options.leftPaneMin, maxSize: options.leftPaneMax, defaultSize: options.leftPaneDef },
                React.createElement(PerfectScrollbar, null,
                    React.createElement(LeftPaneContent, null,
                        header,
                        !options.readonly && options.allowTemplates && (React.createElement(CodeTemplates, { onSelect: onSelectTemplate, defaultOpen: !props.files || props.template ? true : false, ref: codeTemplateRef })),
                        options.allowCodePreview && (React.createElement(PreviewHeader, null,
                            React.createElement(Button, { minimal: true, intent: 'primary', fill: true, icon: 'code', style: { marginTop: 5, marginBottom: 5 }, disabled: !parseResult || parseResult.errors.length > 0, onClick: onCodePreview }, "Code Preview"),
                            React.createElement("small", { className: 'bp4-text-muted' }, "Make sure to fix errors, if any, before previewing the code."))),
                        React.createElement(CodeTree, { onDownload: onDownload, onFilesCreated: onFilesCreated, onFilesRenamed: onFilesRenamed, onFilesDeleted: onFilesDeleted, 
                            // onFilesSelected={onFilesSelected}
                            onFileClicked: onFileClicked, onCopyContent: options.allowCopyContent ? onCopyContent : undefined, files: files, readonly: options.readonly, errors: parseFilesErrors, ref: codeTreeRef, allowedExtensions: options.allowedExtensions }),
                        !options.readonly && storageFull && (React.createElement(StorageFull, null,
                            React.createElement(Callout, { title: 'Storage Full', intent: 'warning', icon: null }, "Your local browser storage seems to be full. You can still do changes, but they won't be saved and need to be committed before closing the page."))))),
                selectedTemplate ? (React.createElement(TemplatePreview, { template: selectedTemplate, contentRenderer: renderFile, onLoad: onLoadSelectedTemplate })) : (React.createElement("div", { style: {
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column'
                    } },
                    React.createElement(CodeTabs, { files: openFiles, activeFile: activeFile, onChange: onTabChange, onClose: onTabClose }),
                    renderActiveFile()))),
            options.allowCodePreview && previewOpen && (React.createElement(PreviewOverlay, null,
                React.createElement(CodeSandbox, { files: previewCompiling ? {} : previewFiles, options: codeSandboxPreviewOptions, header: React.createElement(PreviewHeader, { loading: previewCompiling },
                        React.createElement(Button, { icon: 'arrow-left', intent: 'primary', minimal: true, fill: true, onClick: onCodePreview, disabled: previewCompiling }, "Return to editor"),
                        React.createElement("div", null,
                            React.createElement("small", { className: 'bp4-text-muted' }, "Select a target language to preview the code.")),
                        React.createElement(LangPicker, { value: previewLang, disabled: previewCompiling, onChanged: setPreviewLang })) })))));
    }
}
