import React, { useState, useCallback, useMemo } from 'react';
import { ReportEntityComponent, ReportEntityText } from './styles';
export const ReportEntity = React.memo((props) => {
    const { entity, onClick } = props;
    const [opened, setOpened] = useState(false);
    const handleClick = useCallback(() => {
        onClick(entity);
        setOpened(!opened);
    }, [onClick, opened]);
    const location = useMemo(() => {
        let loc = '';
        if (entity.location) {
            loc += `file: '${entity.location.path}'`;
            if (entity.location.pos) {
                loc += `, line ${entity.location.pos.start.line}, col ${entity.location.pos.start.column}`;
                // if (typeof entity.location.pos.end !== 'undefined') {
                //     loc += `${entity.location.pos.end.line}, col ${entity.location.pos.end.column}`;
                // }
            }
        }
        if (loc === '') {
            loc = null;
        }
        return loc;
    }, [entity.location]);
    return (React.createElement(ReportEntityComponent, { onClick: handleClick, error: entity.type === 'error' },
        React.createElement(ReportEntityText, { opened: opened },
            entity.message,
            location ? ` at {${location}}` : null)));
});
