import * as mon from 'monaco-editor';
let keywords;
const keywordsMap = {};
let keywordsKeys;
function getKeywords() {
    if (!keywords || !keywordsKeys) {
        keywords = [
            {
                aliases: ['package', 'domain'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'package: Declare a package',
                description: 'Package name in the com.example.domain format.',
                snippet: 'package ${1:com.example}\n'
            },
            {
                aliases: ['import'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'import: Declare import',
                description: 'Import types from a different domain. Example: import com.example.{type1,type2}',
                snippet: 'import ${1:name}.{\n  ${2:Type},\n  ${3:Type2}\n}\n'
            },
            {
                aliases: ['include'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'include: Include a file',
                description: 'Include contents of a file as is.',
                snippet: 'include "${1:file.pfm}"\n'
            },
            {
                aliases: ['type'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'type: Declare a type',
                description: 'Declare a type, useful when dealing with templates or when we want to have a distinct type.',
                snippet: 'type ${1:Name} = ${2:TargetType}\n'
            },
            {
                aliases: ['alias'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'alias: Declare a type alias',
                description: 'Declare a type alias, not supported in all languages. If target language does not support - will fallback to the target type.',
                snippet: 'alias ${1:Name} = ${2:TargetType}\n'
            },
            {
                aliases: ['template'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'template: Declare a template',
                description: 'Templates may include generic params, which make templating easy.',
                snippet: 'template [${1:T}] data ${2:Name} {\n  ${3:value}: ${1}\n}\n'
            },
            {
                aliases: ['cloned'],
                completionKind: mon.languages.CompletionItemKind.Class,
                short: 'cloned: Declare a cloned structure',
                description: 'Clone structure from a different type.',
                snippet: 'cloned ${1:Name} from ${2:Type} {\n  ${3:field}: ${4:string}\n}\n'
            },
            {
                aliases: ['id'],
                completionKind: mon.languages.CompletionItemKind.Class,
                short: 'id: Declare an identifier',
                description: 'Specialized identifier',
                snippet: 'id ${1:Name} {\n  ${2:field}: ${3:string}\n}\n'
            },
            {
                aliases: ['enum'],
                completionKind: mon.languages.CompletionItemKind.Enum,
                short: 'enum: Declare an enumeration',
                description: 'Enumeration',
                snippet: 'enum ${1:Name} {\n  ${2:enum1}\n  ${3:enum2}\n}\n'
            },
            {
                aliases: ['adt'],
                completionKind: mon.languages.CompletionItemKind.Class,
                short: 'adt: Declare an algebraic data type',
                description: 'Algebraic data types may present multiple different types under one entity.',
                snippet: 'adt ${1:Name} {\n  ${2:Type1}\n  ${3:Type2}\n}\n'
            },
            {
                aliases: ['const'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'const: Declare constants',
                description: 'Named set of constants.',
                snippet: 'const ${1:Name} {\n  ${2:value}: ${3:u32} = ${4:10}\n}\n'
            },
            {
                aliases: ['data'],
                completionKind: mon.languages.CompletionItemKind.Class,
                short: 'data: Declare data structure',
                description: 'Data structures may implement mixins, have fields.',
                snippet: 'data ${1:Name} {\n  ${2:&Implement}\n  ${3:field}: ${4:u32}\n}\n'
            },
            {
                aliases: ['mixin', 'interface'],
                completionKind: mon.languages.CompletionItemKind.Interface,
                short: 'mixin: Declare mixin structure',
                description: 'Mixin is an interface, which can be used in data structures and within other mixins.',
                snippet: 'mixin ${1:Name} {\n  ${2:&Implement}\n  ${3:field}: ${4:string}\n}\n'
            },
            {
                aliases: ['service'],
                completionKind: mon.languages.CompletionItemKind.Class,
                short: 'service: Declare a service',
                description: 'Service is a corner stone for the API building.',
                snippet: 'service ${1:Name}Service {\n  def ${2:method}(${3:param}: ${4:string}) => ${5:boolean}\n}\n'
            },
            {
                aliases: ['def'],
                completionKind: mon.languages.CompletionItemKind.Class,
                short: 'def: Declare a service method',
                description: 'Service method.',
                snippet: 'def ${1:method}(${2:param}: ${3:string}) => ${4:boolean}\n'
            },
            // Generics
            {
                aliases: ['lst', 'list'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'list',
                description: 'List, e.g. list[string]',
                snippet: 'list[${1:string}]'
            },
            {
                aliases: ['set'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'set',
                description: 'List of unique values, e.g. set[string]',
                snippet: 'set[${1:string}]'
            },
            {
                aliases: ['opt', 'option'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'optional type',
                description: 'Optional type. E.g. option[string]',
                snippet: 'option[${1:string}]'
            },
            {
                aliases: ['map', 'dict'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'map',
                description: 'Map with a key and a value. E.g. map[string, string]',
                snippet: 'map[${1:string},${2:string}]'
            },
            {
                aliases: ['either', 'alt'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'either',
                description: 'Either type holds one of two values, left or right. E.g. either[string, string]',
                snippet: 'either[${1:string},${2:string}]'
            },
            // Attributes
            {
                aliases: ['jsonNull'],
                completionKind: mon.languages.CompletionItemKind.Snippet,
                short: 'Null optionals',
                description: 'Serialize optional types with a null in JSON, by default undefined optionals are skipped.',
                snippet: 'JsonNull()'
            },
            {
                aliases: ['reactService'],
                completionKind: mon.languages.CompletionItemKind.Snippet,
                short: 'React service',
                description: 'Generate react hooks for the service',
                snippet: 'react(\n  queries=[\n    "${1:methodName}",\n    {method="${2:methodName}", name="${3:useQueryName}", optional=${4:true}}\n  ]\n)'
            },
            {
                aliases: ['reactQuery'],
                completionKind: mon.languages.CompletionItemKind.Snippet,
                short: 'React query service method',
                description: 'Generate a react query hook for the service method',
                snippet: 'react(type="query", name="${1:useQueryName}", optional=["${2:paramName}"])'
            },
            {
                aliases: ['reactMutation'],
                completionKind: mon.languages.CompletionItemKind.Snippet,
                short: 'React mutation service method',
                description: 'Generate a react mutation hook for the service method',
                snippet: 'react(name="${1:useMutationName}")'
            }
        ];
        // tslint:enable:no-invalid-template-strings
        keywords.forEach(t => t.aliases.forEach(a => (keywordsMap[a] = t)));
        keywordsKeys = Object.keys(keywordsMap);
    }
    return { keywords, keywordsKeys };
}
let types;
const typesMap = {};
let typesKeys;
function getTypes() {
    if (!types || !typesKeys) {
        types = [
            {
                aliases: ['bit', 'boolean', 'bool'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Boolean',
                description: 'Boolean value which can be true or false.'
            },
            {
                aliases: ['string', 'str'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'String',
                description: 'String type.'
            },
            {
                aliases: ['i08', 'byte', 'int8'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: '8 bit integer',
                description: '8 bit integer, holds values from -127 to 128.'
            },
            {
                aliases: ['u08', 'ubyte', 'uint8'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: '8 bit unsigned integer',
                description: '8 bit unsigned integer, holds values from 0 to 255.'
            },
            {
                aliases: ['i16', 'short', 'int16'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: '16 bit integer',
                description: '16 bit integer'
            },
            {
                aliases: ['u16', 'ushort', 'uint16'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: '16 bit unsigned integer',
                description: '16 bit unsigned integer'
            },
            {
                aliases: ['i32', 'int', 'int32'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: '32 bit integer',
                description: '32 bit integer'
            },
            {
                aliases: ['u32', 'uint32', 'uint'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: '32 bit unsigned integer',
                description: '32 bit unsigned integer'
            },
            {
                aliases: ['i64', 'long', 'int64'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: '64 bit integer',
                description: '64 bit integer'
            },
            {
                aliases: ['u64', 'ulong', 'uint64'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: '64 bit unsigned integer',
                description: '64 bit unsigned integer'
            },
            {
                aliases: ['f32', 'flt', 'float'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Float, 32 bit',
                description: 'Float, 32 bit'
            },
            {
                aliases: ['f64', 'dbl', 'double'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Float, 64 bit',
                description: 'Float, 64 bit'
            },
            {
                aliases: ['bigint', 'big'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'BigInt',
                description: 'BigInt'
            },
            {
                aliases: ['uid', 'uuid'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'UUID',
                description: 'Universal unique identifier'
            },
            {
                aliases: ['blob', 'blb', 'bytes'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Unsigned Byte array',
                description: 'Unsigned Byte array'
            },
            {
                aliases: ['tsl', 'datetimel'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Timestamp, local',
                description: 'Timestamp, local'
            },
            {
                aliases: ['tso', 'datetimeo'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Timestamp with offset',
                description: 'Timestamp with offset'
            },
            {
                aliases: ['tsu', 'datetimeu'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Timestamp, universal',
                description: 'Timestamp, universal'
            },
            {
                aliases: ['time'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Time',
                description: 'Time, e.g. 15:12:30'
            },
            {
                aliases: ['date'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Date',
                description: '2019-10-20'
            },
            {
                aliases: ['error', 'err'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Error',
                description: 'Special error type, should only be used to inherit from for error structures.'
            },
            {
                aliases: ['any'],
                completionKind: mon.languages.CompletionItemKind.Keyword,
                short: 'Any',
                description: 'Special type, should not be used, not cross-functional.'
            }
        ];
        types.forEach(t => t.aliases.forEach(a => (typesMap[a] = t)));
        typesKeys = Object.keys(typesMap);
    }
    return { types, typesKeys };
}
function convertParseLocation(l) {
    // @ts-ignore
    const end = typeof l.pos.end !== 'undefined' ? l.pos.end : l.pos.start;
    return {
        // @ts-ignore
        startLineNumber: l.pos.start.line,
        // @ts-ignore
        startColumn: l.pos.start.column,
        endLineNumber: end.line,
        endColumn: end.column
    };
}
let completionSymbols = [];
let documentDefinitions = {};
let fqnLocation = {};
export function provideDocumentSymbols(ts, files) {
    // Ignore if empty list came in or no data at all,
    // let's reuse the last values
    if (!ts || ts.length === 0) {
        return;
    }
    const pathFileIDS = {};
    if (files) {
        files.forEach(f => (pathFileIDS[f.name] = f.id));
    }
    fqnLocation = {};
    completionSymbols = ts
        .filter(t => (t.pos ? true : false))
        .map(t => {
        let kind = mon.languages.CompletionItemKind.Variable;
        switch (t.class) {
            case 'DTO':
                kind = mon.languages.CompletionItemKind.Class;
                break;
            case 'Interface':
                kind = mon.languages.CompletionItemKind.Interface;
                break;
            case 'Enum':
                kind = mon.languages.CompletionItemKind.Enum;
                break;
            case 'Service':
                kind = mon.languages.CompletionItemKind.Class;
                break;
            case 'Identifier':
                kind = mon.languages.CompletionItemKind.Keyword;
                break;
            default:
        }
        // TODO Not cool to do mutation in mapping
        if (t.pos && t.pos.pos) {
            fqnLocation[t.fqn] = {
                file: `/${t.pos.path}`,
                loc: convertParseLocation(t.pos),
                id: pathFileIDS[t.pos.path]
            };
        }
        return {
            label: t.name,
            kind,
            details: t.pos ? `Declared in ${t.pos.path}` : '',
            insertText: t.name,
            insertTextRules: mon.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            range: {
                startLineNumber: 0,
                startColumn: 0,
                endLineNumber: 0,
                endColumn: 0
            }
        };
    });
    documentDefinitions = {};
    ts.forEach(t => {
        if (!t.pos || !t.pos.pos) {
            return;
        }
        const fileURI = `/${t.pos.path}`;
        const existing = documentDefinitions[fileURI] || { symbols: [], defs: [] };
        let kind = mon.languages.SymbolKind.Class;
        switch (t.class) {
            case 'DTO':
                kind = mon.languages.SymbolKind.Class;
                break;
            case 'Interface':
                kind = mon.languages.SymbolKind.Interface;
                break;
            case 'Enum':
                kind = mon.languages.SymbolKind.Enum;
                break;
            case 'Service':
                kind = mon.languages.SymbolKind.Class;
                break;
            case 'Identifier':
                kind = mon.languages.SymbolKind.Class;
                break;
            default:
        }
        const symbolRange = convertParseLocation(t.pos);
        const symbol = {
            name: t.name,
            kind,
            detail: '',
            range: symbolRange,
            selectionRange: symbolRange,
            tags: []
        };
        existing.symbols.push(symbol);
        if (t.fields) {
            t.fields.forEach(f => {
                if (!f.pos || !f.pos.pos) {
                    return;
                }
                const fqnLoc = fqnLocation[f.typeFQN];
                if (!fqnLoc) {
                    return;
                }
                existing.defs.push({
                    fqn: f.typeFQN,
                    loc: convertParseLocation(f.pos)
                });
            });
        }
        documentDefinitions[fileURI] = existing;
    });
}
export function getProviderSymbol() {
    return {
        provideDocumentSymbols: (model, token) => {
            const defs = documentDefinitions[model.uri.path];
            return defs && defs.symbols ? defs.symbols : [];
        }
    };
}
export function getProviderDefinition() {
    return {
        provideDefinition: (model, position, token) => {
            const defs = documentDefinitions[model.uri.path];
            if (!defs) {
                return [];
            }
            const def = defs.defs.find(d => {
                const tofqn = fqnLocation[d.fqn];
                if (!tofqn) {
                    return false;
                }
                return (d.loc.startLineNumber <= position.lineNumber &&
                    d.loc.endLineNumber >= position.lineNumber &&
                    d.loc.startColumn <= position.column &&
                    d.loc.endColumn >= position.column);
            });
            if (!def) {
                return [];
            }
            const to = fqnLocation[def.fqn];
            if (!to) {
                return [];
            }
            return {
                uri: mon.Uri.from({
                    scheme: 'file',
                    path: to.file,
                    query: `id=${to.id}`
                }),
                range: to.loc
            };
        }
    };
}
export function getProviderCompletion() {
    return {
        provideCompletionItems: (model, position) => {
            const wordAtPos = model.getWordAtPosition(position);
            if (!wordAtPos || wordAtPos.word == null) {
                return {
                    suggestions: []
                };
            }
            const rangeTillPos = model.getWordUntilPosition(position);
            const replaceRange = {
                startLineNumber: position.lineNumber,
                startColumn: rangeTillPos.startColumn,
                endLineNumber: position.lineNumber,
                endColumn: position.column
            };
            // TODO Add support for scopes, so that we show only
            // relevant hints, such as those inside structs,
            // field types, etc.
            const wl = wordAtPos.word.toLowerCase();
            const ks = getKeywords().keywordsKeys
                .filter(k => k.startsWith(wl))
                .map(k => {
                const info = keywordsMap[k];
                return {
                    label: info.short,
                    kind: info.completionKind,
                    details: info.description,
                    insertText: info.snippet,
                    insertTextRules: mon.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    filterText: k,
                    range: replaceRange
                };
            });
            const ts = getTypes().typesKeys
                .filter(k => k.startsWith(wl))
                .map(k => {
                const info = typesMap[k];
                return {
                    label: k,
                    kind: info.completionKind,
                    details: info.description,
                    insertText: k,
                    insertTextRules: mon.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                    range: replaceRange
                };
            });
            const ss = completionSymbols
                .filter(s => s.insertText.toLowerCase().indexOf(wl) >= 0)
                .map(f => (Object.assign(Object.assign({}, f), { range: replaceRange })));
            return {
                suggestions: [...ks, ...ts, ...ss]
            };
        }
    };
}
export function getProviderHover() {
    return {
        provideHover: (model, position) => {
            const hoverWord = model.getWordAtPosition(position);
            if (!hoverWord || hoverWord.word == null) {
                return {
                    contents: []
                };
            }
            const range = new mon.Range(position.lineNumber, hoverWord.startColumn, position.lineNumber, hoverWord.endColumn);
            const kw = getKeywords().keywords.find(k => k.aliases.indexOf(hoverWord.word) >= 0);
            if (kw) {
                return {
                    range,
                    contents: [{ value: kw.description + (kw.aliases.length > 1 ? ` (aliases: ${kw.aliases.join(', ')})` : '') }]
                };
            }
            const ty = getTypes().types.find(t => t.aliases.indexOf(hoverWord.word) >= 0);
            if (ty) {
                return {
                    range,
                    contents: [{ value: ty.description + (ty.aliases.length > 1 ? ` (aliases: ${ty.aliases.join(', ')})` : '') }]
                };
            }
            return {
                contents: []
            };
        }
    };
}
