import styled, { css } from 'styled-components';
export const Entry = styled.div `
  cursor: pointer;
  position: relative;
  padding: 0.3rem 3rem 0.3rem ${props => props.depth * 15}px;
  min-width: 100px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  user-select: none;
  text-decoration: none;
  border-left: 2px solid;
  border-color: transparent;
  color: ${props => (!props.selected ? 'rgba(255, 255, 255, 0.75)' : 'rgb(255, 255, 255)')};
  &:hover {
    ${props => !props.selected &&
    css `
        background-color: rgba(19, 124, 189, 0.125) !important;
        border-color: rgba(19, 124, 189, 0.25) !important;
      `}
  }
  ${props => props.selected
    ? css `
          background-color: rgba(19, 124, 189, 0.25) !important;
          border-color: rgb(19, 124, 189) !important;
        `
    : ''}
`;
export const Right = styled.div `
  display: flex;
  align-items: center;
  position: absolute;
  right: 1rem;
`;
// export const EntryActions = styled.div`
//   position: absolute;
//   right: 1rem;
//   top: 0;
//   bottom: 0;
//   display: flex;
//   align-items: center;
// `;
