var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createContext, useState, useEffect, useRef, useImperativeHandle, useCallback } from 'react';
import { loadZip } from '~/core/zipper';
import { newFileID } from './common';
import { isCodeFile } from '../common';
// @ts-ignore
export const CodeTreeContext = createContext({});
export const defaultAllowedExtensions = ['.pf', '.pfm', '.model', '.domain'];
export function useCodeTreeContextState(props, ref) {
    const [updatedAt, setUpdatedAt] = useState(0);
    const selected = useRef([]);
    const decorated = useRef([]);
    const nodesData = useRef(constructTree(props.files));
    function constructTree(files) {
        const nodes = {};
        const root = {
            id: newFileID(),
            type: 'folder',
            name: '',
            decoration: {},
            children: []
        };
        nodes[root.id] = {
            node: root,
            opened: true
        };
        const selectFolder = (path) => {
            let currentFolder = root;
            for (const chunk of path) {
                const folder = currentFolder.children.find(f => f.type === 'folder' && f.name === chunk);
                if (!folder) {
                    const newFolder = {
                        id: newFileID(),
                        type: 'folder',
                        name: chunk,
                        decoration: {},
                        children: []
                    };
                    nodes[newFolder.id] = {
                        node: newFolder,
                        parent: currentFolder.id
                    };
                    currentFolder.children.push(newFolder);
                    currentFolder = newFolder;
                }
                else {
                    currentFolder = folder;
                }
            }
            return currentFolder;
        };
        (Array.isArray(files) ? files : Object.values(files)).forEach(f => {
            const chunks = f.name.split('/');
            const folder = selectFolder(chunks.slice(0, chunks.length - 1));
            const file = {
                id: f.id,
                type: 'file',
                name: chunks[chunks.length - 1],
                decoration: {}
            };
            folder.children.push(file);
            nodes[file.id] = {
                node: file,
                parent: folder.id
            };
        });
        return { root, nodes };
    }
    const nameValidator = useCallback((name, isFile, node, parentNode) => {
        let pn;
        if (parentNode) {
            pn = parentNode;
        }
        else {
            const treeNode = nodesData.current.nodes[node.id];
            if (!treeNode || !treeNode.parent) {
                return undefined;
            }
            const parentNodeData = nodesData.current.nodes[treeNode.parent];
            if (!parentNodeData || parentNodeData.node.type !== 'folder') {
                return undefined;
            }
            pn = parentNodeData.node;
        }
        const ln = name.toLowerCase();
        if (ln.trim().length === 0) {
            return `Name can't be empty`;
        }
        if (isFile) {
            const ext = name.substr(name.lastIndexOf('.'));
            const allowedExt = props.allowedExtensions || defaultAllowedExtensions;
            if (allowedExt.indexOf(ext) < 0) {
                return `File extension ${ext} is not supported.`;
            }
        }
        // console.log(`Checking ${name} in ${pn.children.map(c => c.name).join(', ')}`);
        return pn.children
            .filter(c => c.id !== node.id)
            .map(c => c.name.toLowerCase())
            .indexOf(ln) >= 0
            ? 'Name already exists'
            : undefined;
    }, [props.allowedExtensions]);
    const forceUpdate = useCallback(() => {
        setUpdatedAt(Date.now());
    }, [setUpdatedAt]);
    const collectNestedNodes = useCallback((node, filesOnly) => {
        const res = [];
        switch (node.type) {
            case 'folder':
                if (!filesOnly) {
                    res.push(node);
                }
                node.children.forEach(c => res.push(...collectNestedNodes(c, filesOnly)));
                break;
            case 'file': {
                res.push(node);
            }
        }
        return res;
    }, []);
    const onClicked = useCallback((node, e) => {
        if (props.onFileClicked) {
            props.onFileClicked(node.id);
        }
    }, [props.onFileClicked]);
    const onSelected = useCallback((node, e) => {
        let needsUpdate = false;
        if (selected.current.length > 0) {
            const updated = {};
            selected.current.forEach(s => {
                const n = nodesData.current.nodes[s];
                updated[s] = Object.assign(Object.assign({}, n), { selected: false });
            });
            nodesData.current.nodes = Object.assign(Object.assign({}, nodesData.current.nodes), updated);
            needsUpdate = true;
        }
        selected.current = [node.id];
        if (needsUpdate) {
            forceUpdate();
        }
        if (props.onFilesSelected) {
            props.onFilesSelected(node.id, selected.current);
        }
    }, [forceUpdate, props.onFilesSelected]);
    const detachFromParent = useCallback((nodeOrID) => {
        const nodeData = typeof nodeOrID === 'string' ? nodesData.current.nodes[nodeOrID] : nodesData.current.nodes[nodeOrID.id];
        const parent = nodeData.parent;
        if (!parent) {
            throw new Error('Trying to delete a root node, not allowed.');
        }
        const parentNode = nodesData.current.nodes[parent];
        if (!parentNode || parentNode.node.type !== 'folder') {
            throw new Error(`Parent node ${parent} is not found, or node type is not folder. Inconsistent model.`);
        }
        const nodeIndex = parentNode.node.children.findIndex(c => c.id === nodeData.node.id);
        if (nodeIndex < 0) {
            throw new Error('Node is not found in parent, inconsistent model.');
        }
        parentNode.node.children.splice(nodeIndex, 1);
        return nodeData;
    }, []);
    const rebuildFilePath = useCallback((node) => {
        let path = node.name;
        let parent = nodesData.current.nodes[node.id].parent;
        while (parent) {
            const n = nodesData.current.nodes[parent];
            if (n.node.id === nodesData.current.root.id) {
                break;
            }
            path = n.node.name + '/' + path;
            parent = n.parent;
        }
        return path;
    }, []);
    const onDelete = useCallback((node) => {
        const collected = collectNestedNodes(node, false);
        detachFromParent(node);
        collected.forEach(c => delete nodesData.current.nodes[c.id]);
        // const beforeSelected = selected.current.length;
        const ids = collected.map(c => c.id);
        selected.current = selected.current.filter(s => ids.indexOf(s) < 0);
        forceUpdate();
        // if (beforeSelected !== selected.current.length && props.onFilesSelected) {
        //     props.onFilesSelected(selected.current);
        // }
        if (props.onFilesDeleted) {
            const filesIds = collected.filter(c => c.type === 'file').map(c => c.id);
            props.onFilesDeleted(filesIds);
        }
    }, [collectNestedNodes, detachFromParent, forceUpdate, props.onFilesDeleted]);
    const onMove = useCallback((id, to) => {
        const nodeData = detachFromParent(id);
        const toNodeData = nodesData.current.nodes[to];
        if (!toNodeData || toNodeData.node.type !== 'folder') {
            throw new Error(`Destination node is not found: ${to} or not a folder.`);
        }
        nodeData.parent = to;
        toNodeData.node.children.push(nodeData.node);
        forceUpdate();
        if (props.onFilesRenamed) {
            // See which files got renamed
            const collected = collectNestedNodes(nodeData.node, true);
            const files = collected
                .filter(c => c.type === 'file')
                .map(c => ({ id: c.id, name: rebuildFilePath(c) }));
            if (files.length > 0) {
                props.onFilesRenamed(files);
            }
        }
    }, [detachFromParent, forceUpdate, collectNestedNodes, rebuildFilePath, props.onFilesRenamed, props.onFilesRenamed]);
    const onRenamed = useCallback((node) => {
        if (!props.onFilesRenamed) {
            return;
        }
        const collect = collectNestedNodes(node, true);
        props.onFilesRenamed(collect.map(c => ({
            id: c.id,
            name: rebuildFilePath(c)
        })));
    }, [props.onFilesRenamed, collectNestedNodes, rebuildFilePath]);
    const createFile = useCallback((node) => {
        nodesData.current.nodes[node.id] = Object.assign(Object.assign({}, nodesData.current.nodes[node.id]), { creating: 'file' });
        forceUpdate();
    }, [forceUpdate]);
    const createFolder = useCallback((node) => {
        nodesData.current.nodes[node.id] = Object.assign(Object.assign({}, nodesData.current.nodes[node.id]), { creating: 'folder' });
        forceUpdate();
    }, [forceUpdate]);
    const addFile = useCallback((node, folder) => {
        nodesData.current.nodes[node.id] = {
            parent: folder.id,
            node
        };
        folder.children.push(node);
        forceUpdate();
        if (props.onFilesCreated) {
            props.onFilesCreated([
                {
                    id: node.id,
                    name: rebuildFilePath(node)
                }
            ]);
        }
    }, [forceUpdate, props.onFilesCreated, rebuildFilePath]);
    const addFolder = useCallback((node, folder) => {
        nodesData.current.nodes[node.id] = {
            parent: folder.id,
            node
        };
        folder.children.push(node);
        forceUpdate();
    }, [forceUpdate]);
    const find = useCallback((path) => {
        const chunks = Array.isArray(path) ? path : path.split('/');
        let cur = nodesData.current.root;
        for (const c of chunks) {
            if (!cur || cur.type !== 'folder') {
                return undefined;
            }
            cur = cur.children.find(ch => ch.name === c);
        }
        return cur;
    }, []);
    const importFiles = useCallback((node, files) => __awaiter(this, void 0, void 0, function* () {
        if (!files || files.length === 0) {
            return;
        }
        const zips = files.filter(f => f.name.toLowerCase().endsWith('.zip'));
        const zipFiles = {};
        yield Promise.all(zips.map(z => new Promise(resolve => {
            const fr = new FileReader();
            fr.onload = () => {
                loadZip(fr.result, (unpackedFiles) => {
                    const unpackedCodeFiles = Object.keys(unpackedFiles).filter(f => isCodeFile(f) && !nameValidator(f, true, node, node));
                    unpackedCodeFiles.forEach(cf => {
                        if (cf in zipFiles) {
                            console.error(`File ${cf} is skipped because another one with the same name is already loaded.`);
                        }
                        else {
                            zipFiles[cf] = unpackedFiles[cf];
                        }
                    });
                    resolve();
                }, (error) => {
                    const msg = `Error during unzipping ${name}: ${error}`;
                    console.error(msg);
                    resolve();
                });
            };
            fr.readAsArrayBuffer(z);
        })));
        const codeFiles = files.filter(f => isCodeFile(f.name) && !nameValidator(f.name, true, node, node));
        const hasInvalid = codeFiles.length !== files.length;
        if (hasInvalid) {
            const invalidFiles = files.filter(f => !isCodeFile(f.name) || !nameValidator(f.name, true, node, node));
            console.warn('Only code files are supported and no duplicate names, ignored:', invalidFiles);
        }
        if (codeFiles.length === 0 && Object.keys(zipFiles).length === 0) {
            return;
        }
        Promise.all(codeFiles.map(f => {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.onload = () => {
                    const content = reader.result;
                    resolve({
                        data: {
                            name: f.name,
                            decoration: {},
                            id: newFileID(),
                            type: 'file'
                        },
                        content
                    });
                };
                reader.readAsText(f);
            });
        })).then((loaded) => {
            const combined = [...loaded];
            Object.keys(zipFiles).forEach(k => {
                if (combined.findIndex(c => c.data.name === k) >= 0) {
                    console.error(`File ${k} is skipped because another one with the same name is already loaded.`);
                    return;
                }
                combined.push({
                    data: {
                        name: k,
                        decoration: {},
                        id: newFileID(),
                        type: 'file'
                    },
                    content: zipFiles[k]
                });
            });
            combined.forEach(n => {
                // If we dropped in a folder, we need to check in the name the path, in case
                // it has path, we need to create a folder structure as needed
                let parentNode = node;
                const chunks = n.data.name.split('/');
                if (chunks.length > 1) {
                    // n.data.name = chunks[chunks.length - 1];
                    const path = chunks.slice(0, chunks.length - 1);
                    for (const p of path) {
                        const f = parentNode.children.find(c => c.type === 'folder' && c.name.toLowerCase() === p.toLowerCase());
                        if (f) {
                            parentNode = f;
                        }
                        else {
                            const newFolder = {
                                type: 'folder',
                                id: newFileID(),
                                children: [],
                                name: p,
                                decoration: {}
                            };
                            nodesData.current.nodes[newFolder.id] = {
                                node: newFolder,
                                parent: parentNode.id
                            };
                            parentNode.children.push(newFolder);
                            parentNode = newFolder;
                        }
                    }
                }
                nodesData.current.nodes[n.data.id] = { node: n.data, parent: node.id };
                parentNode.children.push(n.data);
            });
            forceUpdate();
            if (props.onFilesCreated) {
                props.onFilesCreated(combined.map(n => ({
                    id: n.data.id,
                    name: rebuildFilePath(n.data),
                    content: n.content
                })));
            }
        }, err => {
            console.error('Error while importing files: ' + err, err);
        });
    }), [isCodeFile, nameValidator, newFileID, forceUpdate, props.onFilesCreated, rebuildFilePath]);
    const selectNode = useCallback((id) => {
        if (!id && selected.current.length === 0) {
            return;
        }
        selected.current.forEach(c => {
            const cn = nodesData.current.nodes[c];
            if (cn) {
                nodesData.current.nodes[c] = Object.assign(Object.assign({}, cn), { selected: undefined });
            }
        });
        if (!id) {
            selected.current = [];
        }
        else {
            selected.current = [id];
            const n = nodesData.current.nodes[id];
            nodesData.current.nodes[id] = Object.assign(Object.assign({}, n), { selected: true });
        }
        forceUpdate();
    }, [forceUpdate]);
    useImperativeHandle(ref, () => {
        return {
            select: selectNode
        };
    });
    useEffect(() => {
        nodesData.current = constructTree(props.files);
        selected.current = [];
        forceUpdate();
    }, [props.files]);
    useEffect(() => {
        let hasChanged = false;
        // Cleanup previous decorations
        decorated.current.forEach(d => {
            const pn = nodesData.current.nodes[d];
            if (pn) {
                pn.node.decoration = {};
                hasChanged = true;
            }
        });
        const updated = [];
        if (props.errors) {
            const errors = props.errors;
            Object.keys(props.errors).forEach(k => {
                const es = errors[k];
                const pn = nodesData.current.nodes[k];
                if (pn) {
                    const errs = pn.node.decoration.errors || [];
                    errs.push(...es.map(esr => esr.message));
                    pn.node.decoration.errors = errs;
                    hasChanged = true;
                    updated.push(pn.node.id);
                }
            });
        }
        decorated.current = updated;
        if (hasChanged) {
            forceUpdate();
        }
    }, [props.errors, props.files]);
    useEffect(() => {
        forceUpdate();
    }, [props.readonly]);
    return {
        readonly: props.readonly,
        updatedAt,
        rootNode: nodesData.current.root,
        selected: selected.current,
        nodes: nodesData.current.nodes,
        find,
        nameValidator,
        createFile,
        createFolder,
        forceUpdate,
        onClicked,
        onSelected,
        onUpdated: onRenamed,
        onDelete,
        onMove,
        importFiles,
        addFile,
        addFolder
    };
}
/*
// TODO Add suppot for OpenAPI and dropped zips import
// as well as JSON importer
private onImportZipPicked(event: React.ChangeEvent<HTMLInputElement>) {
    const list = event.target.files;
    // this.importInput.value = null;
    if (!list) {
      return;
    }

    let files: { [path: string]: string } = {};
    let counter = list.length;
    const onIterationComplete = () => {
      counter--;
      if (counter > 0) {
        return;
      }

      // We need to sanitize now all files that don't match our extensions
      const keys = Object.keys(files);
      keys.forEach((k: string) => {
        const ext = k.substr(k.lastIndexOf('.')).toLowerCase();
        if (ImportAllowedFiles.indexOf(ext) >= 0) {
          return;
        }
        delete files[k];
      });

      const filteredKeys = Object.keys(files);
      if (filteredKeys.length === 0) {
        showErrorToast('Nothing to import.');
        return;
      }

      showSuccessToast('Imported ' + filteredKeys.length + ' files.');
      this.bulkImportFiles(files);
    };

    const openAPIParser = new OpenAPIImportParser(true);

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < list.length; i++) {
      const f = list[i];
      // const size = f.size;
      // const type = f.type; // application/zip
      const name = f.name;
      const namelc = name.toLowerCase();
      if (namelc.endsWith('.yaml') || namelc.endsWith('.json')) {
        const fr = new FileReader();
        fr.onload = () => {
          openAPIParser
            .parse(fr.result as string)
            .then((res: OpenAPIImportResult) => {
              onIterationComplete();
              console.log(res);
            })
            .catch((error: string) => {
              showErrorToast(
                'Error during yaml parsing ' + name + ': ' + error,
              );
              onIterationComplete();
            });
        };
        fr.readAsText(f);
      } else {
        // All other ones are text, just load them and put into the map
        const fr = new FileReader();
        fr.onload = () => {
          files[name] = fr.result as string;
          onIterationComplete();
        };
        fr.readAsText(f);
      }
    }
  }
*/
