import styled from 'styled-components';
export const NoContentZone = styled.div `
  height: 250px;
  margin: 5px 15px 15px 15px;
  border-radius: 15px;
  border-style: dashed;
  border-width: 1px;
  border-color: rgb(110, 125, 137);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(110, 125, 137);
  padding: 25px;
`;
