// export const EngineSupportedLanguage = {
//   Scala: "scala",
//   Typescript: "typescript",
//   Java: "java",
//   CSharp: "csharp",
//   Protobuf: "protobuf"
// };
// export type EngineSupportedLanguage = typeof EngineSupportedLanguage[keyof typeof EngineSupportedLanguage];
// export function pfOptions(engine: IDLModule, lang: EngineSupportedLanguage, manifest?: CompileManifest): CompilerOptions {
//   const compilerOptions: CompilerOptions = {
//     extensions: {
//       All: {}
//     },
//     logTranslatorOutput: true,
//     language: {
//     },
//     withBundledRuntime: true,
//     providedRuntime: null,
//     manifest: undefined,
//     packZip: false
//   };
//   switch (lang) {
//       case EngineSupportedLanguage.Scala:
//         compilerOptions.manifest = {
//           ScalaBuildManifest: manifest || engine.Protoforce.scalaManifestExample()
//         };
//         compilerOptions.language.Scala = {};
//         break;
//       case EngineSupportedLanguage.Java:
//         compilerOptions.manifest = {
//           JavaBuildManifest: manifest || engine.Protoforce.javaManifestExample()
//         };
//         compilerOptions.language.Java = {};
//         break;
//       case EngineSupportedLanguage.Typescript:
//         compilerOptions.manifest = {
//           TypeScriptBuildManifest: manifest || engine.Protoforce.tsManifestExample()
//         };
//         compilerOptions.language.Typescript = {};
//         break;
//       case EngineSupportedLanguage.CSharp:
//         compilerOptions.manifest = {
//           CSharpBuildManifest: manifest || engine.Protoforce.csManifestExample()
//         };
//         compilerOptions.language.CSharp = {};
//         break;
//       case EngineSupportedLanguage.Protobuf:
//         compilerOptions.manifest = {
//           ProtobufBuildManifest: manifest || engine.Protoforce.pbManifestExample()
//         };
//         compilerOptions.language.Protobuf = {};
//         break;
//       default:
//         throw new Error(`Language is not supported: ${lang}`);
//     }
//     return compilerOptions;
// }
// export interface Protoforce {
//   typeFs: (data: { fs: { [key: string]: string }; typerOptions: TyperOptions }) => BridgeTyperOutput;
//   compileFs: (data: {
//     fs: { [key: string]: string };
//     typerOptions: TyperOptions;
//     compilerOptions: CompilerOptions;
//   }) => BridgeCompilerOutput;
//   scalaManifestExample: () => CompileManifest;
//   tsManifestExample: () => CompileManifest;
//   javaManifestExample: () => CompileManifest;
//   csManifestExample: () => CompileManifest;
//   pbManifestExample: () => CompileManifest;
// }
// export interface TyperOptions {
//   logProgress: boolean;
//   enableAny: boolean;
// }
// export type CompileManifest = any;
// export interface CompilerOptions {
//   extensions: {
//     All?: {};
//   };
//   logTranslatorOutput: boolean;
//   language: {
//     Scala?: {};
//     Typescript?: {};
//     Java?: {};
//     CSharp?: {};
//     Protobuf?: {};
//   };
//   withBundledRuntime: boolean;
//   providedRuntime: null;
//   manifest: CompileManifest;
//   packZip: boolean;
// }
// export const CompilerDefaultOptions: CompilerOptions = {
//   extensions: {
//     All: {}
//   },
//   logTranslatorOutput: false,
//   language: {
//     Scala: {}
//   },
//   withBundledRuntime: false,
//   providedRuntime: null,
//   manifest: null,
//   packZip: false
// };
// export interface IDLModule {
//   Protoforce: Protoforce;
// }
// // Common models
// export type Path = {
//   [pathClass: string]: {
//     // pathClass: 'Full' | 'Name'
//     location?: string[];
//     name: string;
//   };
// };
// export interface Pos {
//   offset: number;
//   line: number;
//   column: number;
// }
// export interface Position {
//   JustFile?: {
//     file: Path;
//   };
//   Offset?: {
//     file: Path;
//     start: Pos;
//   };
//   Full?: {
//     file: Path;
//     start: Pos;
//     stop: Pos;
//   };
// }
// export interface DomainId {
//   id: string;
//   pkg: string[];
// }
// // Error types
// export type LoadedDomainFailure = {
//   ParsingFailed?: LoadedDomainFailureParsingFailed;
//   ResolutionFailed?: LoadedDomainFailureResolutionFailed;
//   TyperFailed?: LoadedDomainFailureTyperFailed;
// };
// export type TyperFailure = any;
// export type TyperWarning = any;
// export interface ModuleReferenceIssue {
//   domain: DomainId;
//   // It has more nest ones
// }
// export interface LoadedDomainFailureParsingFailed {
//   path: Path;
//   pos?: Pos;
//   message: string;
// }
// export interface LoadedDomainFailureResolutionFailed {
//   path: Path;
//   domain: DomainId;
//   issues: ModuleReferenceIssue[];
// }
// export interface LoadedDomainFailureTyperFailed {
//   path: Path;
//   domain: DomainId;
//   issues: TyperFailure[];
//   warnings: TyperWarning[];
// }
// // Bridge failure
// export interface BridgeTyperFailure {
//   failure: GenericTyperFailure;
// }
// export interface DiagRenderedWarning {
//   message: string;
//   position: Position;
// }
// export interface DiagRenderedFailure {
//   message: string;
//   position: Position;
//   path: Path;
// }
// export interface DiagRenderedDiagnostics {
//   failures: DiagRenderedFailure[];
//   warnings: DiagRenderedWarning[];
// }
// export interface GenericTyperFailureTyperFailed {
//   t: LoadedDomainFailure[];
//   diag: DiagRenderedDiagnostics;
// }
// export interface GenericTyperUnexpectedException {
//   t: string;
// }
// export interface GenericTyperInputParserFailed {
//   t: string;
// }
// export type GenericTyperFailure = {
//   TyperFailed?: GenericTyperFailureTyperFailed;
//   UnexpectedException?: GenericTyperUnexpectedException;
//   InputParserFailed?: GenericTyperInputParserFailed;
// };
// // Bridge success
// export interface BridgeTyperSuccess {
//   models: LoadedDomainSuccess[];
//   warnings: DiagRenderedWarning[];
// }
// // Typespace Types
// export interface DomainID {
//   id: string;
//   pkg: {
//     _path: string[];
//   };
// }
// export interface Typespace {
//   // warnings: any[];
//   // importLoops: any[];
//   // directImports: any[];
//   // version: Version;
//   // meta: TypespaceMeta;
//   // conversions: any[];
//   // origin: Origin;
//   domainId: DomainID;
//   types: ProcessedOp[];
//   // imports: any[];
//   // consts: any[];
// }
// export interface TypespaceImportedType {
//   member: {};
// }
// export interface TypespaceBuiltinTypeID {
//   name: {
//     name: string;
//   };
// }
// export interface TypespaceTypeID {
//   BuiltinTypeId?: TypespaceBuiltinTypeID;
//   UserTypeId?: TypespaceUserTypeID;
// }
// export interface TypespaceUserTypeID {
//   prefix?: {
//     UserTLT?: {
//       location: {
//         domainId: DomainID;
//         sub: any[];
//       };
//     };
//     UserT?: {
//       // location: DomainID;
//     };
//   };
//   name: {
//     name: string;
//   };
// }
// export function typespaceUserTypeIDToFQN(tpe: TypespaceUserTypeID): string {
//   const pref = tpe.prefix
//     ? tpe.prefix.UserTLT
//       ? tpe.prefix.UserTLT.location.domainId.pkg._path.join('.') + '.' + tpe.prefix.UserTLT.location.domainId.id
//       : ''
//     : '';
//   return pref + '.' + tpe.name.name;
// }
// export function typespaceTypeIDToFQN(tpe: TypespaceTypeID): string {
//   if (tpe.BuiltinTypeId) {
//     return tpe.BuiltinTypeId.name.name;
//   } else if (tpe.UserTypeId) {
//     return typespaceUserTypeIDToFQN(tpe.UserTypeId);
//   } else {
//     return '';
//   }
// }
// export interface TypespaceField {
//   name: {
//     name: string;
//   };
//   tpe: {
//     Scalar?: {
//       id: TypespaceTypeID;
//     };
//   };
//   defined: {
//     // to be fileld in
//     in: {};
//     as: {};
//     meta: {
//       doc: any[];
//       annos: any[];
//       pos: Position;
//     };
//   }[];
// }
// export interface TypespaceExportedTypeBase {
//   id: TypespaceUserTypeID;
//   meta: {
//     pos: Position;
//   };
// }
// export interface TypespaceExportedTypeAdt {
//   contract: {
//     id: TypespaceUserTypeID;
//     fields: TypespaceField[];
//     meta: {
//       pos: Position;
//     };
//   };
//   meta: {
//     pos: Position;
//   };
//   // contractDefn
//   // members
//   // raw
//   // serviceLink
// }
// export interface TypespaceExportedTypeDTO {
//   id: TypespaceUserTypeID;
//   meta: {
//     pos: Position;
//   };
//   fields: TypespaceField[];
// }
// export interface TypespaceExportedTypeEnum {
//   id: TypespaceUserTypeID;
//   meta: {
//     pos: Position;
//   };
//   members: {
//     name: string,
//     value: string | number,
//     meta: {
//       doc: any[];
//       annos: any[];
//       pos: Position;
//     }
//   }[];
// }
// export interface TypespaceExportedTypeService {
//   id: TypespaceUserTypeID;
//   meta: {
//     pos: Position;
//   };
//   methods: {
//     // input
//     // meta
//     name: string,
//     // output
//     // restSpec
//   };
// }
// export interface TypespaceExportedType {
//   member: { [key: string]: TypespaceExportedTypeBase | TypespaceExportedTypeDTO | TypespaceExportedTypeAdt | TypespaceExportedTypeService };
// }
// export interface ProcessedOp {
//   Imported?: TypespaceImportedType;
//   Exported?: TypespaceExportedType;
// }
// export interface LoadedDomainSuccess {
//   typespace: Typespace;
// }
// // Bridge typer output
// export interface BridgeTyperOutput {
//   BridgeTyperFailure?: BridgeTyperFailure;
//   BridgeTyperSuccess?: BridgeTyperSuccess;
// }
// // Bridge compiler output
// export interface BridgeCompilerFailure {
//   failure: GenericTyperFailure;
// }
// export interface BridgeCompilerSuccess {
//   fs: { [key: string]: string };
//   warnings: DiagRenderedWarning[];
// }
// export interface BridgeCompilerOutput {
//   BridgeCompilerFailure?: BridgeCompilerFailure;
//   BridgeCompilerSuccess?: BridgeCompilerSuccess;
// }
export const EngineSupportedLanguage = {
    Scala: "scala",
    Typescript: "typescript",
    Java: "java",
    CSharp: "csharp",
    Protobuf: "protobuf"
};
export function pfOptions(engine, lang, manifest) {
    const compilerOptions = {
        extensions: {
            All: {}
        },
        logTranslatorOutput: true,
        language: {},
        withBundledRuntime: true,
        providedRuntime: null,
        manifest: undefined,
        packZip: false
    };
    switch (lang) {
        case EngineSupportedLanguage.Scala:
            compilerOptions.manifest = {
                ScalaBuildManifest: manifest || engine.Protoforce.scalaManifestExample()
            };
            compilerOptions.language.Scala = {};
            break;
        case EngineSupportedLanguage.Java:
            compilerOptions.manifest = {
                JavaBuildManifest: manifest || engine.Protoforce.javaManifestExample()
            };
            compilerOptions.language.Java = {};
            break;
        case EngineSupportedLanguage.Typescript:
            compilerOptions.manifest = {
                TypeScriptBuildManifest: manifest || engine.Protoforce.tsManifestExample()
            };
            compilerOptions.language.Typescript = {};
            break;
        case EngineSupportedLanguage.CSharp:
            compilerOptions.manifest = {
                CSharpBuildManifest: manifest || engine.Protoforce.csManifestExample()
            };
            compilerOptions.language.CSharp = {};
            break;
        case EngineSupportedLanguage.Protobuf:
            compilerOptions.manifest = {
                ProtobufBuildManifest: manifest || engine.Protoforce.pbManifestExample()
            };
            compilerOptions.language.Protobuf = {};
            break;
        default:
            throw new Error(`Language is not supported: ${lang}`);
    }
    return compilerOptions;
}
export const CompilerDefaultOptions = {
    extensions: {
        All: {}
    },
    logTranslatorOutput: false,
    language: {
        Scala: {}
    },
    withBundledRuntime: false,
    providedRuntime: null,
    manifest: null,
    packZip: false
};
