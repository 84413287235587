import React, { useCallback } from 'react';
import { Tab } from './Tab';
import { TabsComponent } from './styles';
export function CodeTabs(props) {
    const { files, activeFile, onChange, onClose } = props;
    // TODO Add dragging and sorting here
    const handleClick = useCallback((id) => {
        return () => onChange(id);
    }, [onChange]);
    const handleClose = useCallback((id) => {
        return () => onClose(id);
    }, [onClose]);
    return (React.createElement(TabsComponent, null, files.map(f => (React.createElement(Tab, { key: f.id, id: f.id, name: f.name, active: activeFile === f.id, onActivate: handleClick(f.id), onClose: handleClose(f.id) })))));
}
