import styled, { css } from 'styled-components';
import { GoChevronDown } from 'react-icons/go';
const ErrorLightColor = '#FF7373';
const ErrorDarkColor = 'rgba(168,42,42,0.75)';
const ErrorDarkColorSemi = 'rgba(168,42,42,0.5)';
const WarningLightColor = 'rgb(203, 166, 0)';
const WarningDarkColor = 'rgba(101,74,7,1)';
const WarningDarkColorSemi = 'rgba(101,74,7,0.75)';
export const CodeReportHeight = 25;
export const ReportEntityComponent = styled.div `
  cursor: pointer;
  position: relative;
  padding: 0.3rem 0.3rem 0.3rem 15px;
  min-width: 100px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  user-select: none;
  text-decoration: none;
  border-left: 2px solid;
  border-color: ${props => (props.error ? ErrorLightColor : WarningLightColor)};
  background-color: ${props => (props.error ? ErrorDarkColorSemi : WarningDarkColorSemi)};
  color: rgba(255, 255, 255, 0.75);
  &:hover {
    background-color: ${props => (props.error ? ErrorDarkColor : WarningDarkColor)};
  }
`;
export const ReportEntityText = styled.span `
  ${props => props.opened
    ? ``
    : css `
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `}
`;
export const ErrorHint = styled.div `
  color: #ffffff;
  background-color: ${ErrorDarkColor};
  position: absolute;
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0px 0px 4px #222;
  border-color: ${ErrorLightColor};
  padding: 5px;
  max-width: 250px;
`;
export const IssueCounter = styled.span `
  color: ${props => (props.zero ? 'rgba(255, 255, 255, 0.4)' : props.error ? ErrorLightColor : WarningLightColor)};
  padding: 4px 5px 3px 3px;
  min-width: 20px;
  height: 20px;
  margin-right: 0.25rem;
  text-align: center;
  padding-left: 0.4rem;
  margin-bottom: 2px;
  text-align: center;
  display: inline-flex;
  background-color: rgba(255, 255, 255, 0.075);
  border-radius: 50%;
  transition: all 0.3s ease 0s;
  align-items: center;
  font-size: 0.75rem;
`;
export const Right = styled.div `
  display: flex;
  align-items: center;
  position: absolute;
  right: 1rem;
  height: 22px;
  text-align: center;
`;
export const ReportComponent = styled.div `
  display: flex;
  flex-direction: column;
  background-color: rgba(26, 34, 39);
  border-top: 1px solid rgba(128, 128, 128, 0.25);
  left: 0;
  right: 0;
  z-index: 1;
`;
// bottom: 0;
// position: absolute;
export const ReportTabsComponent = styled.div `
  display: flex;
  height: ${CodeReportHeight}px;
  overflow: hidden;
  border-bottom: 1px solid rgba(128, 128, 128, 0.25);
`;
export const ReportTabComponent = styled.div `
  position: relative;
  display: flex;
  white-space: nowrap;
  height: ${CodeReportHeight}px;
  box-sizing: border-box;
  padding-left: 10px;
  align-items: center;
  width: 120px;
  min-width: fit-content;
  border-right: 1px solid rgba(17, 21, 24, 0.5);
  &:hover {
    ${props => !props.active &&
    css `
        background-color: rgba(19, 124, 189, 0.125) !important;
      `}
  }
  ${props => props.active &&
    css `
      background-color: rgba(19, 124, 189, 0.25) !important;
    `}
`;
export const ReportTabLabel = styled.div `
  display: flex;
  color: ${props => (props.active ? 'rgb(255, 255, 255)' : 'rgba(255, 255, 255, 0.75)')};
  white-space: nowrap;
  flex: 1;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  white-space: pre;
  text-decoration: none;
  font-size: 13px;
  cursor: ${props => (props.active ? 'default' : 'pointer')};
`;
export const ReportContent = styled.div `
  /* overflow: auto; */
  max-height: 250px;
  min-height: 150px;
`;
export const ReportExpandIcon = styled(GoChevronDown) `
  transition: 0.3s ease all;
  font-size: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;

  transform: rotateZ(${props => (props.open ? 0 : -180)}deg);
`;
