import React, { useState, useCallback } from 'react';
import { Select } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';
import { Lang } from '@protoforce/shared';
import { FileIcon, LangFileIcons } from '~/components/general/FileIcon';
import { codeLangNames, allCodeLangs } from '../core/langs';
const LangSelect = Select.ofType();
const iconSize = 18;
function langName(lang) {
    return codeLangNames[lang];
}
const SupportedLanguages = [
    Lang.TypeScript,
    Lang.Scala,
    Lang.Java,
    Lang.CSharp,
    Lang.Protobuf
];
const sortedAllCodeLangs = allCodeLangs.sort((a, b) => {
    const aSupported = SupportedLanguages.indexOf(a) >= 0;
    const bSupported = SupportedLanguages.indexOf(b) >= 0;
    if (aSupported && !bSupported) {
        return -1;
    }
    if (!aSupported && bSupported) {
        return 1;
    }
    return 0;
});
export const LangPicker = React.memo((props) => {
    const { defaultLang, value, disabled, onChanged } = props;
    const [val, setVal] = useState(value || defaultLang || Lang.TypeScript);
    const [controlled] = useState(typeof value !== 'undefined');
    const onSetLang = useCallback((newLang) => {
        if (!controlled) {
            setVal(newLang);
        }
        onChanged(newLang);
    }, [onChanged, setVal, controlled]);
    const renderLang = useCallback((item, s) => {
        if (!s.modifiers.matchesPredicate) {
            return null;
        }
        const enabled = SupportedLanguages.indexOf(item) >= 0;
        return (React.createElement(MenuItem, { style: { minWidth: 150 }, active: s.modifiers.active, disabled: !enabled || s.modifiers.disabled, key: item.toString(), onClick: s.handleClick, icon: React.createElement(FileIcon, { file: LangFileIcons[item], width: iconSize, height: iconSize }), text: codeLangNames[item] }));
    }, []);
    const v = controlled ? value : val;
    return (React.createElement(LangSelect, { filterable: true, items: sortedAllCodeLangs, itemRenderer: renderLang, onItemSelect: onSetLang, disabled: disabled, popoverProps: {
            minimal: true
        } },
        React.createElement(Button, { className: 'bp4-align-left', text: langName(v), fill: true, style: { minWidth: 120, marginTop: 5 }, disabled: disabled, rightIcon: 'chevron-down', icon: React.createElement(FileIcon, { file: LangFileIcons[v], width: iconSize, height: iconSize }) })));
});
