import React, { forwardRef } from 'react';
import { TreeNode } from './TreeNode';
import { ExplorerBlock } from '../ExplorerBlock';
import { TreeActions } from './TreeActions';
import { useCodeTreeContextState, CodeTreeContext } from './CodeTreeContext';
const CodeTreeImpl = (props, ref) => {
    const { title = 'Files', readonly, onDownload, onCopyContent, header, footer, defaultOpen = true } = props;
    const state = useCodeTreeContextState(props, ref);
    // TODO Add multiselect
    // TODO There is a bug, when a file is selected and then dragged into
    // a different place - selection is lost and won't reselect until select something else
    // before
    function onCreateFile() {
        state.createFile(state.rootNode);
    }
    function onCreateFolder() {
        state.createFolder(state.rootNode);
    }
    return (
    // @ts-ignore
    React.createElement(CodeTreeContext.Provider, { value: state },
        React.createElement(ExplorerBlock, { title: title, stayMounted: true, defaultOpen: defaultOpen, disabled: readonly, actions: React.createElement(TreeActions, { readonly: readonly, forceShow: true, onCreateFile: onCreateFile, onCreateFolder: onCreateFolder, onDownload: onDownload, onCopyContent: onCopyContent }) },
            header,
            React.createElement(TreeNode, { node: state.rootNode, root: true }),
            footer)));
};
export const CodeTree = forwardRef(CodeTreeImpl);
