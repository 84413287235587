var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
export const StorageFull = styled.div `
  padding: 15px;
`;
const barberpole = keyframes `
    from {
        background-position: 0 0;
    }
    to {
        background-position: 60px 30px;
    }
`;
export const NonIdealWrapper = styled.div `
  margin-top: 150px;
  min-width: 250px;
`;
export const PreviewHeader = styled((_a) => {
    var { loading } = _a, props = __rest(_a, ["loading"]);
    return React.createElement("div", Object.assign({}, props));
}) `
  margin: 20px 20px 0px 20px;
  border-radius: 5px;
  ${p => p.loading
    ? css `
          background-size: 30px 30px;
          background-image: linear-gradient(
            45deg,
            rgba(82, 151, 255, 0.1) 25%,
            transparent 25%,
            transparent 50%,
            rgba(82, 151, 255, 0.1) 50%,
            rgba(82, 151, 255, 0.1) 75%,
            transparent 75%,
            transparent
          );
          animation: ${barberpole} 0.5s linear infinite;
        `
    : css `
          background-color: rgba(0, 0, 0, 0.1);
        `}
  padding: 10px 15px 15px 15px;
  text-align: center;
`;
export const LeftPaneContent = styled.div `
  /* overflow: auto;
  height: 100%; */
  padding-bottom: 20px;
`;
export const PreviewOverlay = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #30404d;
  left: 0;
  top: 0;
  z-index: 2;
`;
