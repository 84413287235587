export var CodeFileType;
(function (CodeFileType) {
    CodeFileType[CodeFileType["Code"] = 0] = "Code";
    CodeFileType[CodeFileType["Image"] = 1] = "Image";
    CodeFileType[CodeFileType["Doc"] = 2] = "Doc";
    CodeFileType[CodeFileType["Unknown"] = 3] = "Unknown";
})(CodeFileType || (CodeFileType = {}));
const pfExtensionsMap = {
    domain: 'pf',
    model: 'pf',
    pf: 'pf',
    pfm: 'pf'
};
const codeExtensionsMap = Object.assign({ md: 'markdown', cs: 'csharp', csproj: 'xml', sln: 'text', config: 'xml', props: 'xml', import: 'xml', proto: 'proto', bazel: 'go', scala: 'scala', go: 'go', ts: 'typescript', js: 'javascript', java: 'java', xml: 'xml', nuspec: 'xml', json: 'json', sbt: 'scala', gql: 'graphql', graphql: 'graphql', yaml: 'yaml', sh: 'shell', properties: 'ini', jvmopts: 'ini' }, pfExtensionsMap);
export function getFileExt(name) {
    const periodIndex = name.lastIndexOf('.');
    return periodIndex < 0 ? name : name.toLowerCase().substr(periodIndex + 1);
}
export function isCodeFile(name) {
    return getFileExt(name) in codeExtensionsMap;
}
export function isProtoforceFile(name) {
    return getFileExt(name) in pfExtensionsMap;
}
export function fileToMonacoLang(name) {
    return codeExtensionsMap[getFileExt(name)] || 'text';
}
export function fileTypeFromName(name) {
    if (isCodeFile(name)) {
        return CodeFileType.Code;
    }
    return CodeFileType.Unknown;
}
// TODO Redo with a hook?
// tslint:disable
export function debounce(func, wait, immediate) {
    let timeout;
    return function (...args) {
        let context = this;
        var later = function () {
            timeout = undefined;
            if (!immediate)
                func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            func.apply(context, args);
    };
}
// tslint:enable
