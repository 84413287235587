import React, { useMemo, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { fileTypeFromName } from '../common';
import { TemplatePreviewContainer, TemplateLoadButton, TemplatePreviewHeader, TemplatePreviewTitle, TemplatePreviewNoContent } from './styles';
import { ErrorBoundary } from '../ErrorBoundary';
export const TemplatePreview = React.memo((props) => {
    const { template, onLoad, contentRenderer } = props;
    const onClick = useCallback(() => {
        onLoad(template);
    }, [template]);
    const file = useMemo(() => {
        if (!template.preview) {
            return undefined;
        }
        const prevFile = template.content[template.preview];
        if (!prevFile) {
            console.warn(`Template ${template.name} has invalid preview file ${template.preview}`);
            return undefined;
        }
        return {
            id: template.name,
            name: template.preview,
            content: {
                type: 'local',
                data: prevFile
            },
            hash: '',
            type: fileTypeFromName(template.preview)
        };
    }, [template]);
    return (React.createElement(PerfectScrollbar, null,
        React.createElement(TemplatePreviewContainer, { className: 'fill-dark' },
            React.createElement(TemplatePreviewHeader, null,
                React.createElement("div", null,
                    React.createElement(TemplatePreviewTitle, null, template.name),
                    React.createElement("small", { className: 'bp4-text-muted' }, template.description)),
                React.createElement(TemplateLoadButton, { onClick: onClick }, "Load template")),
            React.createElement(ErrorBoundary, null, template.preview ? (contentRenderer && file ? (contentRenderer(file)) : (template.preview)) : (React.createElement(TemplatePreviewNoContent, { className: 'bp4-text-muted' }, "This template provides no preview."))))));
});
