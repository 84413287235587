import { Lang, LangHelper } from '@protoforce/shared';
import { EngineSupportedLanguage } from './engine';
export const allCodeLangs = LangHelper.all;
export const codeLangNames = {
    [Lang.CSharp]: 'CSharp',
    [Lang.TypeScript]: 'JS/TS',
    [Lang.Scala]: 'Scala',
    [Lang.Golang]: 'Go',
    [Lang.Curl]: 'Curl',
    [Lang.Java]: 'Java',
    [Lang.Kotlin]: 'Kotlin',
    [Lang.PHP]: 'PHP',
    [Lang.Python]: 'Python',
    [Lang.Ruby]: 'Ruby',
    [Lang.Rust]: 'Rust',
    [Lang.Swift]: 'Swift',
    [Lang.CPP]: 'CPP',
    [Lang.Protobuf]: 'Protobuf'
};
export function idlToEngineLang(l) {
    switch (l) {
        case Lang.TypeScript:
            return EngineSupportedLanguage.Typescript;
        case Lang.Scala:
            return EngineSupportedLanguage.Scala;
        case Lang.Java:
            return EngineSupportedLanguage.Java;
        case Lang.CSharp:
            return EngineSupportedLanguage.CSharp;
        case Lang.Protobuf:
            return EngineSupportedLanguage.Protobuf;
        default:
            return undefined;
    }
}
