import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import * as monaco from 'monaco-editor';
import { pfDef } from './pf/monaco-lang-pf';
import { provideDocumentSymbols } from './pf/pf-providers';
import { MonacoEditorWrapper } from './styles';
import { disposeMonaco, initMonaco, prepareMonacoModel } from './monaco';
const MonacoEditorImpl = (props, ref) => {
    const { readonly, className, style, onChange, activeFile, errors, symbols } = props;
    const editorEl = useRef(null);
    const onChangeRef = useRef({ onChange, activeFile });
    const modelsMarkers = useRef([]);
    const [editor, setEditor] = useState();
    const [openFile, setOpenFile] = useState(() => props.activeFile);
    const [models] = useState(() => {
        const res = {};
        props.files.forEach(f => (res[f.id] = prepareMonacoModel(f)));
        return res;
    });
    function loadModel(f) {
        models[f.id] = prepareMonacoModel(f);
    }
    const switchModel = (newActiveFile) => {
        setOpenFile(newActiveFile);
        if (!editor || newActiveFile === openFile) {
            return;
        }
        // Save previous state
        if (openFile) {
            const prevModel = models[openFile];
            // Check here in case the model was deleted from the map
            if (prevModel) {
                prevModel.view = editor.saveViewState();
                // prevModel.model = editor.getModel();
            }
        }
        const m = newActiveFile ? models[newActiveFile] : undefined;
        if (!m) {
            editor.setModel(null);
            return;
        }
        // Load new state
        editor.setModel(m.model);
        if (m.view) {
            editor.restoreViewState(m.view);
        }
        editor.focus();
    };
    function gotoFile(id, pos) {
        if (!editor || !models[id]) {
            console.warn(`File with id ${id} is not found.`);
            return;
        }
        if (activeFile !== id) {
            switchModel(id);
        }
        if (!pos) {
            // Nothing to do, just open the requested file
            return;
        }
        editor.setPosition({
            lineNumber: pos.line,
            column: pos.column
        });
        editor.focus();
    }
    useImperativeHandle(ref, () => {
        return {
            gotoFile,
            loadModel
        };
    });
    // Initialize the editor for the first time and cleanup on unmount
    useEffect(() => {
        initMonaco();
        const editorInstance = monaco.editor.create(editorEl.current, {
            theme: 'pfdark',
            readOnly: readonly,
            // TODO Consider doing manual relayouting, to speed things up a bit
            automaticLayout: true,
            scrollBeyondLastLine: false,
            selectOnLineNumbers: true,
            // glyphMargin: true,
            minimap: {
                enabled: false
            }
        }, {
        // codeEditorService: {
        //     openCodeEditor: (
        //         input: {resource: {path: string}, options: {selection: any}},
        //         ed: monaco.editor.ICodeEditor, sideBySide?: boolean) => {
        //             gotoFile(input.resource.path);
        //             ed.setSelection(input.options.selection);
        //             ed.revealLine(input.options.selection.startLineNumber);
        //             return {
        //                 getControl: () => ed,
        //             };
        //         },
        //     addCodeEditor: () => {},
        // }, // Object.assign(Object.create(codeEditorService),
        // codeEditorService: {
        //     openCodeEditor: (
        //         input: {resource: {path: string}, options: {selection: any}},
        //         ed: monaco.editor.ICodeEditor, sideBySide?: boolean) => {
        //         gotoFile(input.resource.path);
        //         ed.setSelection(input.options.selection);
        //         ed.revealLine(input.options.selection.startLineNumber);
        //         return {
        //             getControl: () => ed,
        //         };
        //     },
        //     addCodeEditor: () => {
        //         alert(`open editor called!` + JSON.stringify(arguments));
        //     },
        // },
        });
        setEditor(editorInstance);
        const onChangeDisposable = editorInstance.onDidChangeModelContent((e) => {
            if (!onChangeRef.current.activeFile) {
                console.warn('Inconsistent state. Model changed, while having no open file.');
                return;
            }
            if (onChangeRef.current.onChange) {
                onChangeRef.current.onChange(onChangeRef.current.activeFile, editorInstance.getValue());
            }
        });
        if (openFile) {
            const openModel = models[openFile];
            if (pfDef.id === openModel.languageID) {
                // We use one of the languages which are initialized with monaco,
                // let's reset it
                monaco.editor.setModelLanguage(openModel.model, openModel.languageID);
            }
            editorInstance.setModel(openModel.model);
        }
        return () => {
            onChangeDisposable.dispose();
            editorInstance.dispose();
            disposeMonaco(Object.values(models));
        };
    }, []);
    // If open files list has changed, load new models
    useEffect(() => {
        const previousIDs = Object.keys(models);
        const updatedIDs = {};
        props.files.forEach(f => {
            updatedIDs[f.id] = true;
            // TODO This is dangerous if remote content slips in
            const content = f.content.data;
            const existingModel = models[f.id];
            if (existingModel) {
                // // Cleanup existing model, maybe content has
                // // changed or something ?
                // existingModel.model.dispose();
                // Or just change text as here?
                existingModel.model.setValue(content);
            }
            else {
                models[f.id] = prepareMonacoModel(f);
            }
        });
        // Check if our active file has become invalid
        if (openFile && !(openFile in updatedIDs)) {
            setOpenFile(undefined);
            if (editor) {
                editor.setModel(null);
            }
        }
        // Remove models for files that are no longer in the list of open files
        previousIDs.forEach(id => {
            if (id in updatedIDs) {
                return;
            }
            // Unload no longer needed models
            models[id].model.dispose();
            delete models[id];
        });
        modelsMarkers.current = [];
    }, [props.files]);
    // Update our model if onChange changed
    useEffect(() => {
        onChangeRef.current = {
            onChange,
            activeFile
        };
    }, [onChange, activeFile]);
    useEffect(() => {
        provideDocumentSymbols(symbols, props.files);
        // console.log(symbols);
    }, [symbols, props.files]);
    // Update open model, when openFile changes
    useEffect(() => {
        // console.log('Active File change: from ' + openFile + ' to ' + activeFile);
        switchModel(activeFile);
    }, [activeFile]);
    // useEffect(() => {
    //     console.log(symbols);
    // }, [symbols]);
    useEffect(() => {
        if (!errors) {
            return;
        }
        modelsMarkers.current.forEach(marker => {
            const m = models[marker];
            if (!m) {
                return;
            }
            monaco.editor.setModelMarkers(m.model, 'MonacoEditor', []);
        });
        Object.keys(errors).forEach(k => {
            const errs = errors[k];
            const m = models[k];
            if (!m) {
                return;
            }
            const markers = [];
            errs.forEach(e => {
                if (!e.location || !e.location.pos) {
                    return;
                }
                const start = e.location.pos.start;
                const end = e.location.pos.end || Object.assign(Object.assign({}, e.location.pos.start), { column: e.location.pos.start.column + 3 });
                markers.push({
                    message: e.message,
                    startLineNumber: start.line,
                    startColumn: start.column,
                    endLineNumber: end.line,
                    endColumn: end.column + 3,
                    severity: monaco.MarkerSeverity.Error
                });
            });
            monaco.editor.setModelMarkers(m.model, 'MonacoEditor', markers);
            modelsMarkers.current.push(k);
        });
    }, [errors]);
    // Update user read only mode
    useEffect(() => {
        if (!editor) {
            return;
        }
        editor.updateOptions({
            readOnly: readonly
        });
    }, [readonly]);
    return React.createElement(MonacoEditorWrapper, { readonly: readonly, className: className, ref: editorEl, style: style });
};
export const MonacoEditor = forwardRef(MonacoEditorImpl);
