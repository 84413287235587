import React, { forwardRef, useMemo, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';
import { templates } from './templates';
import { CodeFileType } from '../common';
import { CodeTree } from '../CodeTree';
import { TemplateHeader, TemplateHeaderText } from './styles';
const CodeTemplatesImpl = (props, ref) => {
    const { onSelect, defaultOpen } = props;
    const templateFiles = useMemo(() => {
        const res = {};
        Object.keys(templates).forEach(k => {
            const v = templates[k];
            res[k] = {
                name: v.name,
                id: k,
                content: { type: 'local', data: '' },
                hash: '',
                type: CodeFileType.Unknown
            };
        });
        return res;
    }, []);
    const onFileClicked = useCallback((activeTemplate) => {
        const template = templates[activeTemplate];
        if (!template) {
            return;
        }
        onSelect(template);
    }, [onSelect]);
    return (React.createElement(CodeTree, { title: 'Templates', files: templateFiles, readonly: true, onFileClicked: onFileClicked, defaultOpen: defaultOpen, ref: ref, header: React.createElement(TemplateHeader, null, "Select one of the available templates to continue."), footer: React.createElement(TemplateHeader, null,
            React.createElement(TemplateHeaderText, null, "Loading a template will replace your project files with those from the template."),
            React.createElement(Icon, { icon: 'arrow-down', iconSize: 24 })) }));
};
export const CodeTemplates = forwardRef(CodeTemplatesImpl);
