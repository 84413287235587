import React, { useRef, useState, useEffect } from 'react';
import * as monaco from 'monaco-editor';
import { File as PFFile } from '@protoforce/projects'; // , FileContent as PFFileContent, ProvidedContent
import { fileTypeFromName } from '../common';
import { MonacoEditorWrapper } from './styles';
import { disposeMonaco, initMonaco, prepareMonacoModel } from './monaco';
export function MonacoDiff(props) {
    const { className, style, left, right } = props;
    const editorEl = useRef(null);
    const [editor, setEditor] = useState();
    const [[leftContent, rightContent] /*, setContents*/] = useState(() => {
        return [prepareContent(left), prepareContent(right)];
    });
    function prepareContent(value) {
        let f;
        if (value instanceof PFFile) {
            f = {
                name: value.name,
                id: value.name,
                hash: value.hash.value,
                type: fileTypeFromName(value.name),
                content: value.content.match(whenProvided => ({
                    type: 'local',
                    data: whenProvided.data
                }), whenRemove => ({
                    type: 'remote',
                    downloading: false,
                    url: whenRemove.url
                }))
            };
        }
        else {
            f = value;
        }
        return prepareMonacoModel(f, true);
    }
    // Initialize the editor for the first time and cleanup on unmount
    useEffect(() => {
        initMonaco();
        const editorInstance = monaco.editor.createDiffEditor(editorEl.current, {
            theme: 'pfdark',
            readOnly: true,
            enableSplitViewResizing: true,
            // TODO Consider doing manual relayouting, to speed things up a bit
            automaticLayout: true,
            scrollBeyondLastLine: false,
            selectOnLineNumbers: true,
            minimap: {
                enabled: false
            }
        });
        setEditor(editorInstance);
        editorInstance.focus();
        monaco.editor.setModelLanguage(leftContent.model, leftContent.languageID);
        monaco.editor.setModelLanguage(rightContent.model, rightContent.languageID);
        editorInstance.setModel({
            original: leftContent.model,
            modified: rightContent.model
        });
        // editorInstance.getOriginalEditor().setModel(leftContent.model);
        // editorInstance.getModifiedEditor().setModel(rightContent.model);
        return () => {
            editorInstance.dispose();
            const lm = editorInstance.getOriginalEditor().getModel();
            const rm = editorInstance.getModifiedEditor().getModel();
            if (lm) {
                lm.dispose();
            }
            if (rm) {
                rm.dispose();
            }
            disposeMonaco();
        };
    }, []);
    useEffect(() => {
        editor === null || editor === void 0 ? void 0 : editor.layout();
    });
    useEffect(() => {
        var _a, _b;
        // const lc = prepareContent(left);
        // const rc = prepareContent(right);
        // editor?.getOriginalEditor().getModel()?.setValue(left)
        // setContents([lc, rc]);
        if (editor) {
            // editor.getOriginalEditor().setModel(leftContent.model);
            // editor.getModifiedEditor().setModel(rightContent.model);
            // const lfc = left.content as FileContent;
            // const leftText = lfc.type === 'local' ? lfc.data : lfc.url;
            // const rfc = right.content as FileContent;
            // const rightText = rfc.type === 'local' ? rfc.data : rfc.url;
            const leftText = left instanceof PFFile ? left.content.match(whenProvided => whenProvided.data, whenRemote => `Remote content: ${whenRemote.url}`) : left.content.type === 'local' ? left.content.data : `Remote content: ${left.content.url}`;
            const rightText = right instanceof PFFile ? right.content.match(whenProvided => whenProvided.data, whenRemote => `Remote content: ${whenRemote.url}`) : right.content.type === 'local' ? right.content.data : `Remote content: ${right.content.url}`;
            (_a = editor.getOriginalEditor().getModel()) === null || _a === void 0 ? void 0 : _a.setValue(leftText || '');
            (_b = editor.getModifiedEditor().getModel()) === null || _b === void 0 ? void 0 : _b.setValue(rightText || '');
        }
    }, [left, right]);
    return React.createElement(MonacoEditorWrapper, { readonly: true, ref: editorEl, className: className, style: style });
}
