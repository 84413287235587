import { guid } from '~/core/utils';
export var NodeStatus;
(function (NodeStatus) {
    NodeStatus[NodeStatus["New"] = 0] = "New";
    NodeStatus[NodeStatus["Modified"] = 1] = "Modified";
})(NodeStatus || (NodeStatus = {}));
// Drag & Drop support
export const DragNodeType = 'node';
export function compareNodes(a, b) {
    const aIsFolder = a.type === 'folder';
    const bIsFolder = b.type === 'folder';
    if (!aIsFolder && !bIsFolder) {
        const ad = a;
        const bd = b;
        return ad.name.localeCompare(bd.name);
    }
    if (aIsFolder && bIsFolder) {
        return a.name.localeCompare(b.name);
    }
    const aFolder = aIsFolder ? 1 : 0;
    const bFolder = bIsFolder ? 1 : 0;
    return bFolder - aFolder;
}
export function newFileID() {
    return guid();
}
