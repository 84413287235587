import styled, { css } from 'styled-components';
// TODO Unify all colors here...
const ErrorTextColor = '#FF7373';
export const TitleContainer = styled.span `
  display: inline-block;
  margin-left: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  ${props => props.error
    ? css `
          color: ${ErrorTextColor};
        `
    : undefined}
`;
