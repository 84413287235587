import React from 'react';
import { FileNode } from './FileNode';
import { FolderNode } from './FolderNode';
export const TreeNode = React.memo((props) => {
    const { node, root, depth = 0 } = props;
    switch (node.type) {
        case 'file':
            return React.createElement(FileNode, { node: node, depth: depth });
        case 'folder':
            return React.createElement(FolderNode, { node: node, depth: depth, root: root });
    }
});
