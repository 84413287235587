import ParserWorker from 'worker-loader?name=static/workers/[hash:8].[name].js!./parser.worker';
// import ParserWorker from './parser.worker';
import { CONFIG } from '~/core';
import { ParserLatestVersion } from './versions';
class ParserService {
    constructor() {
        this.jobs = {};
        this.onMessage = this.onMessage.bind(this);
        this.onError = this.onError.bind(this);
        if (!CONFIG.ssr) {
            this.worker = new ParserWorker();
            this.worker.onmessage = this.onMessage;
            this.worker.onerror = this.onError;
        }
        else {
            this.worker = {
                postMessage: (message, options) => {
                    // Do nothing here
                },
                onmessage: null,
                onerror: null
            };
        }
    }
    onError(message) {
        console.error('Worker error', message);
    }
    onMessage(message) {
        const data = message.data;
        console.debug('ParserService response', JSON.stringify(data));
        const cb = this.jobs[data.id];
        if (typeof cb !== 'undefined') {
            delete this.jobs[data.id];
        }
        else {
            console.warn('Unknown job result for ID: ' + data.id, data);
            return;
        }
        switch (data.job) {
            case 'parse': {
                cb(data.result);
                break;
            }
            case 'compile': {
                cb(data.result);
                break;
            }
            default:
                console.warn('Unsupported job result', data);
        }
    }
    parse(files, cb, engineVersion = ParserLatestVersion) {
        console.debug('ParserService request', JSON.stringify(files));
        const job = {
            job: 'parse',
            engineVersion,
            id: Date.now().toString(),
            files
        };
        this.jobs[job.id] = cb;
        this.worker.postMessage(job);
    }
    compile(files, language, cb, engineVersion = ParserLatestVersion) {
        console.debug('CompilerService request', JSON.stringify(files));
        const job = {
            job: 'compile',
            engineVersion,
            id: Date.now().toString(),
            files,
            language
        };
        this.jobs[job.id] = cb;
        this.worker.postMessage(job);
    }
}
export const parserService = new ParserService();
