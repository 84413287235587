import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useDrag } from 'react-dnd';
import { FileIcon } from '~/components/general/FileIcon';
import { DragNodeType } from '../../common';
import { TitleInput } from '../TitleInput';
import { Title } from '../Title';
import { Right, Entry } from '../styles';
import { TreeActions } from '../../TreeActions';
import { CodeTreeContext } from '../../CodeTreeContext';
export const FileNode = React.memo((props) => {
    const { node, depth = 0 } = props;
    const context = useContext(CodeTreeContext);
    const treeNode = context.nodes[node.id];
    const [selected, setSelected] = useState(treeNode.selected);
    const [editing, setEditing] = useState(false);
    const [hovering, setHovering] = useState(false);
    const [{ isDragging }, drag] = useDrag({
        item: { type: DragNodeType, node: node.id, name: node.name },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    });
    const validateName = useCallback((v) => {
        return context.nameValidator(v, true, node);
    }, [context]);
    useEffect(() => {
        setSelected(treeNode.selected);
    }, [treeNode]);
    const onMouseEnter = useCallback(() => {
        setHovering(true);
    }, [setHovering]);
    const onMouseLeave = useCallback(() => {
        setHovering(false);
    }, [setHovering]);
    const onCommit = useCallback((value, blur) => {
        setEditing(false);
        node.name = value;
        context.onUpdated(node);
    }, [setEditing, context.onUpdated, node]);
    const onCancel = useCallback(() => {
        setEditing(false);
    }, [setEditing]);
    const onEdit = useCallback(() => {
        setEditing(true);
    }, [setEditing]);
    const onDelete = useCallback(() => {
        context.onDelete(node);
    }, [context.onDelete]);
    const onSelect = useCallback((e) => {
        if (!selected) {
            setSelected(true);
            context.onSelected(node, e);
        }
        context.onClicked(node, e);
    }, [context.onSelected, context.onClicked, selected, setSelected]);
    return (React.createElement(Entry, { ref: drag, depth: depth, selected: selected, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onClick: onSelect, style: isDragging
            ? {
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move'
            }
            : undefined },
        React.createElement(FileIcon, { file: node.name }),
        editing ? (React.createElement(TitleInput, { title: node.name, nameValidator: validateName, onCommit: onCommit, onCancel: onCancel })) : (React.createElement(Title, { title: node.name, error: node.decoration.errors && node.decoration.errors.length > 0 ? true : false })),
        React.createElement(Right, null,
            React.createElement(TreeActions, { readonly: context.readonly, hovering: hovering && !editing, onDelete: onDelete, onEdit: onEdit }))));
});
