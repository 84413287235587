import React, { useState, useCallback } from 'react';
import { Animate as ReactShow } from 'react-show';
import { BlockContainer, BlockHeader, BlockTitle, BlockActions, BlockExpandIconContainer } from './styles';
export function ExplorerBlock(props) {
    const { defaultOpen, style, children, title, stayMounted, disabled, overflow, actions, markers } = props;
    const [open, setOpen] = useState(defaultOpen);
    const toggle = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BlockHeader, { style: style, onClick: toggle },
            React.createElement(BlockExpandIconContainer, { open: open }),
            React.createElement(BlockTitle, null, title),
            open && actions && React.createElement(BlockActions, null, actions),
            markers),
        React.createElement(ReactShow, { style: {
                height: 'auto',
                overflow: overflow ? 'initial' : 'hidden'
            }, transitionOnMount: true, start: {
                height: 0
            }, show: open, duration: 250, stayMounted: stayMounted },
            React.createElement(BlockContainer, { disabled: disabled }, children))));
}
