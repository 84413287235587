import React, { useMemo, useCallback } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';
import { FileIcon } from '~/components/general/FileIcon';
import { TabComponent, TabLabel, TabClose } from './styles';
export const Tab = React.memo((props) => {
    const { id, name, active, onActivate, onClose } = props;
    const nameOnly = useMemo(() => {
        const slash = name.lastIndexOf('/');
        return slash >= 0 ? name.substr(slash + 1) : name;
    }, [name, id]);
    const onClick = useCallback(() => {
        if (active) {
            return;
        }
        onActivate();
    }, [active, onActivate]);
    const onCloseClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        onClose();
    }, [onClose]);
    return (React.createElement(TabComponent, { onClick: onClick, active: active },
        React.createElement(FileIcon, { file: name }),
        React.createElement(TabLabel, { active: active }, nameOnly),
        React.createElement(TabClose, { onClick: onCloseClick },
            React.createElement(CloseIcon, null))));
});
