import styled from 'styled-components';
export const TemplateHeader = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(110, 125, 137);
  padding: 10px 20px 10px 20px;
`;
export const TemplateHeaderText = styled.span `
  margin: 5px;
`;
export const TemplatePreviewContainer = styled.div `
  /* height: 100%; */
  padding: 15px 15px 15px 15px;
  /* overflow: auto; */
`;
export const TemplatePreviewHeader = styled.div `
  border-radius: 15px;
  border-style: dashed;
  border-width: 1px;
  border-color: rgb(110, 125, 137);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(110, 125, 137);
  padding: 25px;
`;
export const TemplatePreviewTitle = styled.h1 `
  margin: 0;
`;
export const TemplatePreviewNoContent = styled.span `
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 15px;
`;
export const TemplateLoadButton = styled.button `
  background-color: rgba(64, 169, 243, 0.5);
  background-size: 720%;
  box-sizing: border-box;
  text-align: center;
  color: white;
  font-weight: 600;
  min-width: 160px;
  min-height: 48px;
  user-select: none;
  font-size: 0.875em;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  outline: none;
  border-width: 2px;
  border-style: solid;
  border-color: rgba(102, 185, 244, 0.5);
  border-image: initial;
  border-radius: 4px;
  text-decoration: none;
  &:hover {
    background-color: rgb(64, 169, 243);
    border-color: rgb(102, 185, 244);
  }
`;
