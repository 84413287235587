import styled from 'styled-components';
const ErrorTextColor = '#FF7373';
const ErrorTextBackground = 'rgba(168,42,42,0.2)';
const ErrorHintBackground = 'rgba(168,42,42,1)';
export const InputContainer = styled.div `
  display: inline-block;
  overflow: auto;
  input {
    transition: 0.3s ease all;
    font-family: inherit;
    outline: none;
    border: none;
    background-color: ${props => (props.errorMessage ? ErrorTextBackground : 'rgba(0, 0, 0, 0.2)')};
    padding-left: 0.25rem;
    margin-left: 0.25rem;
    color: ${props => (props.errorMessage ? ErrorTextColor : '#FFFFFF')};
  }
`;
export const InputError = styled.div `
  color: #ffffff;
  background-color: ${ErrorHintBackground};
  position: absolute;
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0px 0px 4px #222;
  border-color: ${ErrorTextColor};
  padding: 5px;
  max-width: 250px;
`;
