export var ParserEngineVersion;
(function (ParserEngineVersion) {
    ParserEngineVersion[ParserEngineVersion["v002"] = 0] = "v002";
    ParserEngineVersion[ParserEngineVersion["v001"] = 1] = "v001";
    ParserEngineVersion[ParserEngineVersion["v003"] = 2] = "v003";
    ParserEngineVersion[ParserEngineVersion["v004"] = 3] = "v004";
    ParserEngineVersion[ParserEngineVersion["v005"] = 4] = "v005";
})(ParserEngineVersion || (ParserEngineVersion = {}));
export const EngineVersions = {
    [ParserEngineVersion.v002]: 'engine.v0.0.2.aaf615de6701.js',
    [ParserEngineVersion.v001]: 'engine.v0.0.1.34a47f60d570.js',
    [ParserEngineVersion.v003]: 'engine.v0.0.3.03a1aba7fb6d.js',
    [ParserEngineVersion.v004]: 'engine.v0.0.4.21fb21b72f9f.js',
    [ParserEngineVersion.v005]: 'engine.v0.0.5.f3eaba0746fb.js'
};
export const ParserLatestVersion = ParserEngineVersion.v005;
