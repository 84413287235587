import React, { useCallback } from 'react';
import { Tooltip } from '@blueprintjs/core';
import { GoPencil as EditIcon } from 'react-icons/go';
import { MdInsertDriveFile as AddFileIcon, MdDelete as DeleteIcon, MdCreateNewFolder as AddDirectoryIcon, MdFileUpload as UploadFileIcon, MdFileDownload as DownloadIcon, MdContentCopy as ContentCopyIcon } from 'react-icons/md';
import { IconContainer, Icon } from './styles';
export const TreeActions = React.memo((props) => {
    const { readonly, className, hovering, forceShow, onCreateFile, onCreateFolder, onUploadFile, onDelete, onEdit, onDownload, onCopyContent } = props;
    const handleClick = useCallback((func) => {
        return (e) => {
            e.preventDefault();
            e.stopPropagation();
            func();
        };
    }, []);
    return (React.createElement("div", { className: className },
        readonly && (React.createElement(IconContainer, null,
            onDownload && (React.createElement(Tooltip, { content: 'Export to ZIP', className: 'bp4-dark' },
                React.createElement(Icon, { onClick: handleClick(onDownload) },
                    React.createElement(DownloadIcon, null)))),
            onCopyContent && (React.createElement(Tooltip, { content: 'Copy Content', className: 'bp4-dark' },
                React.createElement(Icon, { onClick: handleClick(onCopyContent) },
                    React.createElement(ContentCopyIcon, null)))))),
        (hovering || forceShow) && !readonly && (React.createElement(IconContainer, null,
            onDownload && (React.createElement(Tooltip, { content: 'Export to ZIP', className: 'bp4-dark' },
                React.createElement(Icon, { onClick: handleClick(onDownload) },
                    React.createElement(DownloadIcon, null)))),
            onUploadFile && (React.createElement(Tooltip, { content: 'Upload Files', className: 'bp4-dark' },
                React.createElement(Icon, { onClick: handleClick(onUploadFile) },
                    React.createElement(UploadFileIcon, null)))),
            onEdit && (React.createElement(Tooltip, { content: 'Rename', className: 'bp4-dark' },
                React.createElement(Icon, { onClick: handleClick(onEdit) },
                    React.createElement(EditIcon, null)))),
            onCreateFile && (React.createElement(Tooltip, { content: 'New File', className: 'bp4-dark' },
                React.createElement(Icon, { onClick: handleClick(onCreateFile) },
                    React.createElement(AddFileIcon, null)))),
            onCreateFolder && (React.createElement(Tooltip, { content: 'New Directory', className: 'bp4-dark' },
                React.createElement(Icon, { onClick: handleClick(onCreateFolder) },
                    React.createElement(AddDirectoryIcon, null)))),
            onDelete && (React.createElement(Tooltip, { content: 'Delete', className: 'bp4-dark' },
                React.createElement(Icon, { onClick: handleClick(onDelete) },
                    React.createElement(DeleteIcon, null)))),
            onCopyContent && (React.createElement(Tooltip, { content: 'Copy Content', className: 'bp4-dark' },
                React.createElement(Icon, { onClick: handleClick(onCopyContent) },
                    React.createElement(ContentCopyIcon, null))))))));
});
