const LocalStoragePrefix = 'ProtoForce:CodeSandbox:';
export function hasInStorage(key) {
    return loadFromStorage(key) ? true : false;
}
export function saveToStorage(key, data) {
    try {
        localStorage.setItem(LocalStoragePrefix + key, data);
        return true;
    }
    catch (_a) {
        return false;
    }
}
export function loadFromStorage(key) {
    try {
        const loaded = localStorage.getItem(LocalStoragePrefix + key);
        if (!loaded) {
            return undefined;
        }
        return loaded;
    }
    catch (_a) {
        return undefined;
    }
}
export function clearStorage(key) {
    localStorage.removeItem(LocalStoragePrefix + key);
}
export function keysInStorage() {
    const res = [];
    for (let i = 0; i < localStorage.length; i++) {
        const k = localStorage.key(i);
        if (!k || !k.startsWith(LocalStoragePrefix)) {
            continue;
        }
        res.push(k.substr(LocalStoragePrefix.length));
    }
    return res;
}
export const defaultStorage = {
    has: hasInStorage,
    save: saveToStorage,
    load: loadFromStorage,
    clear: clearStorage,
    keys: keysInStorage
};
