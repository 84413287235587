// https://microsoft.github.io/monaco-editor/monarch.html
// import IRichLanguageConfiguration = monaco.languages.LanguageConfiguration;
// import ILanguage = monaco.languages.IMonarchLanguage;
export const pfDef = {
    id: 'pf',
    extensions: ['.pf', '.model', '.domain', '.pfm'],
    aliases: ['PF', 'pf']
};
export const pfConf = {
    comments: {
        lineComment: '//',
        blockComment: ['/*', '*/']
    },
    brackets: [
        ['{', '}'],
        ['[', ']'],
        ['(', ')']
    ],
    autoClosingPairs: [
        { open: '[', close: ']' },
        { open: '{', close: '}' },
        { open: '(', close: ')' },
        // prettier-ignore
        { open: '\'', close: '\'', notIn: ['string', 'comment'] },
        { open: '"', close: '"', notIn: ['string'] }
    ],
    surroundingPairs: [
        { open: '{', close: '}' },
        { open: '[', close: ']' },
        { open: '(', close: ')' },
        { open: '"', close: '"' }
    ]
};
// Debug here
// https://microsoft.github.io/monaco-editor/monarch.html
export const pfLanguage = {
    tokenPostfix: '.pf',
    // Set defaultToken to invalid to see what you do not tokenize yet
    // defaultToken: 'invalid',
    defaultToken: 'source',
    keywords: [
        'package',
        'domain',
        'import',
        'include',
        'type',
        'alias',
        'template',
        'cloned',
        'id',
        'enum',
        'adt',
        'const',
        'data',
        'service',
        'mixin',
        'interface',
        'def'
    ],
    typeKeywords: [
        'bit',
        'boolean',
        'bool',
        'string',
        'str',
        'i08',
        'byte',
        'int8',
        'bigint',
        'big',
        'i16',
        'short',
        'int16',
        'i32',
        'int',
        'int32',
        'i64',
        'long',
        'int64',
        'u08',
        'ubyte',
        'uint8',
        'u16',
        'ushort',
        'uint16',
        'u32',
        'uint32',
        'uint',
        'u64',
        'ulong',
        'uint64',
        'f32',
        'flt',
        'float',
        'f64',
        'dbl',
        'double',
        'uid',
        'uuid',
        'blob',
        'blb',
        'bytes',
        'tsl',
        'datetimel',
        'tso',
        'datetimeo',
        'tsu',
        'datetimeu',
        'time',
        'date',
        'error',
        'err',
        'any',
        'lst',
        'list',
        'set',
        'opt',
        'option',
        'map',
        'dict',
        'either',
        'alt'
    ],
    operators: ['!', '+', '+++', '-', ',', '...', '.', '=>', '&', '|'],
    // we include these common regular expressions
    symbols: /[=><!~?:&|+\-*\/\^%]+/,
    // C# style strings
    escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,
    // The main tokenizer for our languages
    tokenizer: {
        root: [
            // identifiers and keywords
            [
                /[a-z_$][\w$]*/,
                { cases: { '@typeKeywords': 'keyword', '@keywords': 'keyword', '@default': 'key.identifier' } }
            ],
            [/[A-Z][\w\$]*/, 'type.identifier'],
            // whitespace
            { include: '@whitespace' },
            // annotation
            { include: '@annotation' },
            // delimiters and operators
            [/[{}()\[\]]/, '@brackets'],
            [/[<>](?!@symbols)/, '@brackets'],
            [/@symbols/, { cases: { '@operators': 'operator', '@default': '' } }],
            // numbers
            [/\d*\.\d+([eE][\-+]?\d+)?/, 'number.float'],
            [/\d+/, 'number'],
            // delimiter: after number because of .\d floats
            [/[;,.]/, 'delimiter'],
            // strings
            [/"([^"\\]|\\.)*$/, 'string.invalid'],
            [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],
            // characters
            [/'[^\\']'/, 'string'],
            [/(')(@escapes)(')/, ['string', 'string.escape', 'string']],
            [/'/, 'string.invalid']
        ],
        // @ annotations.
        // As an example, we emit a debugging log message on these tokens.
        // Note: message are supressed during the first load -- change some lines to see them.
        annotation: [[/@\s*[a-zA-Z0-9_\$][\w\$]*/, 'annotation']],
        comment: [
            [/[^\/*]+/, 'comment'],
            [/\*\//, 'comment', '@pop'],
            [/[\/*]/, 'comment']
        ],
        // jsdoc: [
        //   [/[^\/*]+/, 'comment.doc'],
        //   [/\*\//, 'comment.doc', '@pop'],
        //   [/[\/*]/, 'comment.doc']
        // ],
        string: [
            [/[^\\"]+/, 'string'],
            [/@escapes/, 'string.escape'],
            [/\\./, 'string.escape.invalid'],
            [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }]
        ],
        whitespace: [
            [/[ \t\r\n]+/, 'white'],
            //   [/\/\*\*(?!\/)/, 'comment.doc', '@jsdoc'],
            [/\/\*/, 'comment', '@comment'],
            [/\/\/.*$/, 'comment']
        ]
    }
};
