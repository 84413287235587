import React, { useState, useRef, useEffect, useCallback } from 'react';
import { InputContainer, InputError } from './styles';
const KEY_ENTER = 13;
const KEY_ESC = 27;
export const TitleInput = React.memo((props) => {
    const { title, nameValidator, onChange, onCommit, onCancel } = props;
    const [value, setValue] = useState(title);
    const inputRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState(nameValidator ? nameValidator(title) : undefined);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.select();
        }
    }, []);
    function handleChange(e) {
        if (!e.target) {
            return;
        }
        const v = e.target.value;
        if (onChange) {
            onChange(v);
        }
        setValue(v);
        if (nameValidator) {
            const validation = nameValidator(v);
            if (validation !== errorMessage) {
                setErrorMessage(validation);
            }
        }
    }
    const handleKeyUp = useCallback((e) => {
        if (e.which === KEY_ENTER || e.keyCode === KEY_ENTER) {
            onCommit(value.trim());
        }
        else if (e.which === KEY_ESC || e.keyCode === KEY_ESC) {
            onCancel();
        }
    }, [onCommit, onCancel, value]);
    const handleBlur = useCallback(() => {
        if (nameValidator && typeof nameValidator(value) !== 'undefined') {
            onCancel();
            return;
        }
        onCommit(value, true);
    }, [nameValidator, onCancel, onCommit, value]);
    return (React.createElement(InputContainer, { errorMessage: errorMessage },
        React.createElement("input", { onChange: handleChange, onBlur: handleBlur, onKeyUp: handleKeyUp, ref: inputRef, value: value, autoFocus: true }),
        errorMessage && React.createElement(InputError, null, errorMessage)));
});
