import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { FaRegFolder as FolderIcon, FaRegFolderOpen as FolderOpenIcon } from 'react-icons/fa';
import { FileIcon } from '~/components/general/FileIcon';
import { compareNodes, DragNodeType, newFileID } from '../../common';
import { TitleInput } from '../TitleInput';
import { Title } from '../Title';
import { Right, Entry } from '../styles';
import { TreeActions } from '../../TreeActions';
import { Folder } from './styles';
import { TreeNode } from '../TreeNode';
import { CodeTreeContext } from '../../CodeTreeContext';
import { NoContentZone } from '../../styles';
export function FolderNode(props) {
    const { node, root, depth = 0 } = props;
    const context = useContext(CodeTreeContext);
    const treeNode = context.nodes[node.id];
    const [editing, setEditing] = useState(false);
    const [creating, setCreating] = useState(treeNode.creating);
    const [creatingName, setCreatingName] = useState(treeNode.creating ? 'file.model' : 'folder');
    // const [selected, setSelected] = useState(false);
    const [open, setOpen] = useState(treeNode.opened);
    const [hovering, setHovering] = useState(false);
    const validateName = useCallback((v) => {
        return context.nameValidator(v, false, node);
    }, [context.nameValidator, node]);
    const validateNewName = useCallback((v) => {
        return context.nameValidator(v, creating === 'file', node, node);
    }, [context.nameValidator, creating, node]);
    const canDropNode = useCallback((item) => {
        if (item.type === DragNodeType) {
            const nodeData = context.nodes[item.node];
            return (nodeData &&
                nodeData.parent !== node.id &&
                nodeData.node.id !== node.id &&
                !context.nameValidator(item.name, nodeData.node.type === 'file', nodeData.node));
        }
        // TODO Add support for folders
        // https://github.com/react-dnd/react-dnd-html5-backend/issues/8
        // if (item.files) {
        //     return true;
        // }
        return true;
    }, [context.nodes, context.nameValidator, node]);
    const onDrop = useCallback((item, monitor) => {
        const res = {
            type: DragNodeType,
            node: node.id
        };
        const isOver = monitor.isOver({ shallow: true });
        const didDrop = monitor.didDrop();
        if (didDrop || !canDropNode(item) || !isOver) {
            return res;
        }
        switch (item.type) {
            case 'node':
                context.onMove(item.node, node.id);
                break;
            default:
                context.importFiles(node, item.files);
        }
        return res;
    }, [node, canDropNode, context.onMove, context.importFiles]);
    const [{ isDropOver, canDrop }, drop] = useDrop({
        accept: [DragNodeType, NativeTypes.FILE],
        drop: onDrop,
        canDrop: canDropNode,
        collect: monitor => ({
            isDropOver: !!monitor.isOver({ shallow: true }),
            canDrop: !!monitor.canDrop()
        })
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: DragNodeType, node: node.id, name: node.name },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    });
    useEffect(() => {
        setOpen(treeNode.opened || treeNode.creating ? true : false);
        if (treeNode.creating) {
            openTree();
            setCreating(treeNode.creating);
            setCreatingName(treeNode.creating === 'file' ? 'file.pf' : 'folder');
            treeNode.creating = undefined;
        }
    }, [treeNode]);
    const onMouseEnter = useCallback(() => {
        setHovering(true);
    }, [setHovering]);
    const onMouseLeave = useCallback(() => {
        setHovering(false);
    }, [setHovering]);
    const openTree = useCallback(() => {
        if (open) {
            return;
        }
        setOpen(true);
    }, [setOpen, open]);
    const onCreateFile = useCallback(() => {
        openTree();
        setCreating('file');
        setCreatingName('file.pf');
    }, [openTree, setCreating, setCreatingName]);
    const onCreateFolder = useCallback(() => {
        openTree();
        setCreating('folder');
        setCreatingName('folder');
    }, [openTree, setCreating, setCreatingName]);
    const onCommit = useCallback((value, blur) => {
        setEditing(false);
        node.name = value;
        context.onUpdated(node);
    }, [setEditing, node, context.onUpdated]);
    const onCancel = useCallback(() => {
        setEditing(false);
    }, [setEditing]);
    const onCreateChange = useCallback((value) => {
        setCreatingName(value);
    }, [setCreatingName]);
    const onCreateCommit = useCallback((value, blur) => {
        if (!blur && !validateNewName(value)) {
            switch (creating) {
                case 'file':
                    context.addFile({
                        type: 'file',
                        id: newFileID(),
                        name: value,
                        decoration: {}
                    }, node);
                    break;
                case 'folder':
                    context.addFolder({
                        type: 'folder',
                        id: newFileID(),
                        name: value,
                        decoration: {},
                        children: []
                    }, node);
                    break;
            }
        }
        setCreating(undefined);
    }, [node, validateNewName, creating, context.addFile, context.addFolder, newFileID, setCreating]);
    const onCreateCancel = useCallback(() => {
        setCreating(undefined);
    }, [setCreating]);
    const onEdit = useCallback(() => {
        setEditing(true);
    }, [setEditing]);
    const onDelete = useCallback(() => {
        context.onDelete(node);
    }, [context.onDelete, node]);
    const toggleOpen = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);
    // function closeTree() {
    //     if (!open) {
    //         return;
    //     }
    //     setOpen(false);
    // }
    return (React.createElement(Folder, { ref: drop, root: root, isDropOver: isDropOver && canDrop, style: isDragging
            ? {
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move'
            }
            : undefined },
        React.createElement("div", { ref: drag },
            !root && (React.createElement(Entry, { depth: depth, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onClick: toggleOpen },
                open ? React.createElement(FolderOpenIcon, null) : React.createElement(FolderIcon, null),
                editing ? (React.createElement(TitleInput, { title: node.name, nameValidator: validateName, onCommit: onCommit, onCancel: onCancel })) : (React.createElement(Title, { title: node.name })),
                React.createElement(Right, null,
                    React.createElement(TreeActions, { readonly: context.readonly, hovering: hovering && !editing, onCreateFile: onCreateFile, onCreateFolder: onCreateFolder, onDelete: onDelete, onEdit: onEdit })))),
            creating && (React.createElement(Entry, { ref: drag, depth: depth + 1 },
                creating === 'folder' ? React.createElement(FolderIcon, null) : React.createElement(FileIcon, { file: creatingName }),
                React.createElement(TitleInput, { title: creatingName, nameValidator: validateNewName, onChange: onCreateChange, onCommit: onCreateCommit, onCancel: onCreateCancel }))),
            open && node.children.sort(compareNodes).map(f => React.createElement(TreeNode, { key: f.id, node: f, depth: depth + 1 })),
            root && node.children.length === 0 && (React.createElement(NoContentZone, null,
                "No files yet. Create a new",
                React.createElement("br", null),
                " one, or drop in here.")))));
}
