var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as monaco from 'monaco-editor';
// import { loadWASM } from 'onigasm'; // peer dependency of 'monaco-textmate'
// import onigasm from 'onigasm/lib/onigasm.wasm';
// import await { onigasm } from './pf/onigasm.wasm';
// import { Registry } from 'monaco-textmate'; // peer dependency
// import { wireTmGrammars } from 'monaco-editor-textmate';
// import scalaLang from './pf/scala.tmLanguage.json';
import pfThemeDark from './pf/monaco-theme-dark.json';
import { pfLanguage, pfConf, pfDef } from './pf/monaco-lang-pf';
// import { gqlLanguage, gqlConf, gqlDef } from './pf/monaco-lang-gql';
import { getProviderHover, getProviderCompletion, getProviderSymbol, getProviderDefinition } from './pf/pf-providers';
import { fileToMonacoLang } from '../common';
const monacoState = {
    refs: 0,
    disposables: []
};
// let loadingWasm = false;
const hasLanguage = (id) => {
    return monaco.languages.getLanguages().find((l) => l.id === id) ? true : false;
};
function registerLanguage(def, conf, lang) {
    if (hasLanguage(def.id)) {
        return;
    }
    monaco.languages.register(def);
    monaco.languages.setMonarchTokensProvider(def.id, lang);
    monaco.languages.setLanguageConfiguration(def.id, conf);
    // monaco.languages.onLanguage(def.id, () => {
    //     // TODO Do we need to Dispose those two langauges
    //     // it probably is called only once, according to docs
    //     // on first time the language is accessed
    //     monaco.languages.setMonarchTokensProvider(def.id, lang);
    //     monaco.languages.setLanguageConfiguration(def.id, conf);
    // });
    if (def.id === pfDef.id) {
        monacoState.disposables.push(monaco.languages.registerHoverProvider(def.id, getProviderHover()), monaco.languages.registerCompletionItemProvider(def.id, getProviderCompletion()), monaco.languages.registerDocumentSymbolProvider(def.id, getProviderSymbol()), monaco.languages.registerDefinitionProvider(def.id, getProviderDefinition())
        // TODO Add more providers:
        // The type definition provider interface defines the contract between
        // extensions and the go to type definition feature.
        // https://microsoft.github.io/monaco-editor/api/interfaces/monaco.languages.typedefinitionprovider.html
        // monaco.languages.registerDefinitionProvider();
        );
    }
}
export function initMonaco() {
    return __awaiter(this, void 0, void 0, function* () {
        if (monacoState.refs > 0) {
            monacoState.refs += 1;
            return;
        }
        // @ts-ignore
        monaco.editor.defineTheme('pfdark', pfThemeDark);
        monaco.editor.setTheme('pfdark');
        registerLanguage(pfDef, pfConf, pfLanguage);
        // registerLanguage(gqlDef, gqlConf, gqlLanguage);
        // if (!hasLanguage('scala') && !loadingWasm) {
        //   loadingWasm = true;
        //   await loadWASM(onigasm); // See https://www.npmjs.com/package/onigasm#light-it-up
        //   const registry = new Registry({
        //     getGrammarDefinition: async () => {
        //       // scopeName: string, dependentScope: string
        //       return {
        //         format: 'json',
        //         // TODO Load this scalaLang in async way
        //         content: scalaLang
        //       };
        //     }
        //   });
        //   // map of monaco "language id's" to TextMate scopeNames
        //   const grammars = new Map();
        //   grammars.set('scala', 'source.scala');
        //   // grammars.set('pf', 'source.protoforce');
        //   // monaco.languages.register({ id: 'pf' });
        //   monaco.languages.register({ id: 'scala' });
        //   await wireTmGrammars(monaco, registry, grammars);
        // }
        monacoState.refs = 1;
        // console.trace('Initialized the editor.');
    });
}
export function disposeMonaco(models) {
    if (models && models.length > 0) {
        models.forEach(m => m.model.dispose());
    }
    if (monacoState.refs > 0) {
        monacoState.refs -= 1;
        if (monacoState.refs === 0) {
            monacoState.disposables.forEach(d => d.dispose());
            monacoState.disposables = [];
            console.trace('Disposed the editor.');
        }
        return;
    }
}
export function prepareMonacoModel(f, addRandom = false) {
    const postfix = addRandom ? '_' + (new Date().getTime()) : '';
    const languageID = fileToMonacoLang(f.name);
    return {
        model: monaco.editor.createModel(
        // TODO This is dangerous if remote content slips in
        f.content.data, languageID, 
        // monaco.Uri.file(f.name + `?id=${f.id}`),
        monaco.Uri.from({
            scheme: 'file',
            path: f.name,
            query: `id=${f.id}${postfix}`
        })),
        languageID
    };
}
