import React, { useState, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ReportTabLabel, IssueCounter, ReportComponent, ReportTabsComponent, ReportTabComponent, ReportContent, ReportExpandIcon, Right } from './styles';
import { ReportEntity } from './ReportEntity';
var ReportTab;
(function (ReportTab) {
    ReportTab[ReportTab["Errors"] = 0] = "Errors";
    ReportTab[ReportTab["Warnings"] = 1] = "Warnings";
    ReportTab[ReportTab["Symbols"] = 2] = "Symbols";
})(ReportTab || (ReportTab = {}));
export const CodeReport = React.memo((props) => {
    const { errors, warnings, symbols, onErrorClick, onWarningClick } = props;
    const [activeTab, setActiveTab] = useState(ReportTab.Errors);
    const [open, setOpen] = useState(false);
    const onTabClick = useCallback((tab) => {
        return () => {
            if (activeTab === tab) {
                setOpen(!open);
                return;
            }
            setActiveTab(tab);
            if (!open) {
                setOpen(true);
            }
        };
    }, [setOpen, setActiveTab, activeTab, open]);
    const onToggle = useCallback(() => {
        setOpen(!open);
    }, [open, setOpen]);
    const onEntityClick = useCallback((errOrWarn) => {
        switch (errOrWarn.type) {
            case 'error':
                onErrorClick(errOrWarn);
                break;
            case 'warning':
                onWarningClick(errOrWarn);
                break;
        }
    }, [onErrorClick, onWarningClick]);
    function renderContent() {
        if (activeTab === ReportTab.Symbols) {
            return (React.createElement("pre", { style: { paddingLeft: 20, paddingRight: 20 } }, symbols &&
                symbols
                    .map(s => `${s.name} [${s.class}] ${s.pos
                    ? `${s.pos.path}${s.pos.pos ? ` at ${s.pos.pos.start.line},${s.pos.pos.start.column}` : ''}`
                    : ''}`)
                    .sort()
                    .join('\n')));
        }
        const entities = activeTab === ReportTab.Errors ? errors : warnings;
        return entities.map((e, ei) => React.createElement(ReportEntity, { key: ei, entity: e, onClick: onEntityClick }));
    }
    return (React.createElement(ReportComponent, null,
        React.createElement(ReportTabsComponent, null,
            React.createElement(ReportTabComponent, { active: activeTab === ReportTab.Errors, onClick: onTabClick(ReportTab.Errors) },
                React.createElement(ReportTabLabel, null, "Errors"),
                React.createElement(IssueCounter, { error: true, zero: errors.length === 0 }, errors.length)),
            React.createElement(ReportTabComponent, { active: activeTab === ReportTab.Warnings, onClick: onTabClick(ReportTab.Warnings) },
                React.createElement(ReportTabLabel, null, "Warnings"),
                React.createElement(IssueCounter, { zero: warnings.length === 0 }, warnings.length)),
            React.createElement(ReportTabComponent, { active: activeTab === ReportTab.Symbols, onClick: onTabClick(ReportTab.Symbols) },
                React.createElement(ReportTabLabel, null, "Symbols"),
                React.createElement(IssueCounter, { zero: true }, symbols ? symbols.length : '?'))),
        open && React.createElement(PerfectScrollbar, null,
            React.createElement(ReportContent, null, renderContent())),
        React.createElement(Right, null,
            React.createElement(ReportExpandIcon, { open: open, onClick: onToggle }))));
});
